/* @media only screen and (max-device-width: 1640px){
  .topographic1Parent {
    position: absolute;
    top: 1740px;
    left: calc(50% - 820px);
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    width: 1640px;
    height: 820px;
    overflow: hidden;
  }
}
@media only screen and (max-device-width: 1440px){ */
.frameChild,
.groupParent {
  position: absolute;
  overflow: hidden;
}
.frameChild {
  height: 73.45%;
  width: 38.76%;
  top: 19.86%;
  right: 7.5%;
  bottom: 6.7%;
  left: 53.74%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.insti {
  top: 546px;
  left: 750px;
  width: 158px;
  height: 24px;
  color:blue;
  font-weight: 600;
  font-size: 20px;
}

.insti,
.web2,
.web3 {
  position: absolute;
  display: flex;
  top: 680px;
  font-weight: 600;
  align-items: center;
}
.web3 {
  top: 150px;
  left: 1262px;
  width: 132px;
  height: 24px;
  color:#3c3c3b;
  font-weight: 600;
  font-size: 20px;
}
.web2{
  top:564px;
  left:607px;
  color:#fa58b6;
  width:137px;
  font-size: 20px;
}
.groupParent {
  top: 0;
  /* left: 0; */
  left: calc(50% - 720px);
  /* transform: translate(-50%, 0); */
  background-color: var(--color-white);
  width: 1440px;
  height: 760px;
}
.groupChild,
.topographic1Icon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.topographic1Icon {
  height: 125.2%;
  width: 63.9%;
  top: 37.32%;
  right: -21.12%;
  bottom: -62.51%;
  left: 57.22%;
  object-fit: contain;
}
.groupChild {
  height: 32.52%;
  width: 0.08%;
  top: 21.78%;
  right: 88.19%;
  bottom: 45.71%;
  left: 11.73%;
}
.groupContainer {
  position: absolute;
  height: 79.51%;
  width: 90%;
  top: 10.24%;
  right: 5%;
  bottom: 10.24%;
  left: 5%;
}
.topographic1Parent {
  position: absolute;
  top: 1765px;
  left: calc(50% - 720px);
  background: linear-gradient(103.94deg, #041a3b, #10479b);
  width: 1440px;
  height: 820px;
  overflow: hidden;
}
.desktop1 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 3515px;
  overflow: hidden;
}
/* } */
@media only screen and (max-device-width: 440px){
  .insti,
  .web2,
  .web3,
  .frameChild {
    display: none;
  }
  .groupParent {
    top: 0;
    /* left: 0; */
    left: calc(50% - 720px);
    /* transform: translate(-50%, 0); */
    background-color: var(--color-white);
    width: 1440px;
    height: 660px;
  }
  .topographic1Parent {
    position: absolute;
    top: 1450px;
    left: calc(50% - 720px);
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    width: 1440px;
    height: 940px;
    overflow: hidden;
  }
  .desktop1 {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    height: 2890px;
    overflow: hidden;
  }
}

/* @media only screen and (max-device-width: 440px){
  .topographic1Parent {
    position: absolute;
    top: 1480px;
    left: calc(50% - 720px);
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    width: 1440px;
    height: 940px;
    overflow: hidden;
  }
} */

