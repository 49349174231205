.bg {
  /* background: radial-gradient(
    50% 50%at 50% 50%,
    #b13589,
    #c62f94 79.31%,
    #8a3ac8
  ); */
  position: absolute;
  height: 87.5%;
  width: 87.5%;
  top: 6.25%;
  right: 6.25%;
  bottom: 6.25%;
  left: 6.25%;
  border-radius: var(--br-7xs);
  /* background-color: var(--color-steelblue); */
}
.bg,
.bg1,
.bg2 {
  position: absolute;
  height: 87.5%;
  width: 87.5%;
  top: 6.25%;
  right: 6.25%;
  bottom: 6.25%;
  left: 6.25%;
  border-radius: var(--br-7xs);
}
.bg1 {
  background: radial-gradient(
    50% 50%at 50% 50%,
    #e0e8b7,
    #fb8a2e 44.47%,
    #e2425c 71.47%,
    rgba(226, 66, 92, 0)
  );
}
.bg2 {
  background: radial-gradient(
    50% 50%at 50% 50%,
    #406adc 15.67%,
    #6a45be 46.78%,
    rgba(106, 69, 190, 0)
  );
}
.instagramIcon {
  position: absolute;
  height: 92.5%;
  width: 62.5%;
  /* top: 2.75%; */
  right: 18.75%;
  bottom: 18.75%;
  left: 18.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.instagram {
  width: 32px;
  height: 32px;
}
