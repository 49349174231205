.featuresOf {
  font-weight: 500;
}
.span {
  font-weight: 600;
}
.featuresOfComplereContainer1 {
  width: 100%;
}
.featuresOfComplereContainer {
  position: absolute;
  width: 26.85%;
  top: 0;
  left: 0;
  font-size: var(--font-size-23xl);
  color: var(--color-white);
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
}
.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background: linear-gradient(
    180deg,
    rgba(250, 88, 182, 0.13),
    rgba(249, 112, 192, 0.25)
  );
}
.regulationSvgrepoCom1Icon {
  position: absolute;
  height: 80%;
  width: 80%;
  top: 10%;
  right: 10%;
  bottom: 10%;
  left: 10%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangleParent {
  position: absolute;
  height: 34.48%;
  width: 15.23%;
  top: 0;
  right: 84.77%;
  bottom: 65.52%;
  left: 0;
}
.complereChainPrioritizes,
.regulationCompliant {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
}
.complereChainPrioritizes {
  top: 35.63%;
  line-height: 28px;
}
.regulationCompliant {
  height: 34.48%;
  top: 0;
  font-size: var(--font-size-9xl);
  font-weight: 600;
  color: var(--color-white);
}
.complereChainPrioritizesRegParent {
  position: absolute;
  height: 100%;
  width: 80.71%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 19.29%;
}
.groupItem,
.groupParent {
  position: absolute;
  height: 26.69%;
  width: 30.4%;
  top: 0;
  right: 37.11%;
  bottom: 73.31%;
  left: 32.48%;
}
.groupItem {
  height: 34.48%;
  width: 15.23%;
  right: 84.77%;
  bottom: 65.52%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupContainer,
.groupDiv {
  position: absolute;
  height: 26.69%;
  width: 30.4%;
  top: 0;
  right: 0;
  bottom: 73.31%;
  left: 69.6%;
}
.groupDiv {
  top: 34.05%;
  right: 37.11%;
  bottom: 39.26%;
  left: 32.48%;
}
.accountSettingsSvgrepoCom1Icon {
  position: absolute;
  height: 70%;
  width: 70%;
  top: 15%;
  right: 15%;
  bottom: 15%;
  left: 15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupParent1 {
  position: absolute;
  height: 26.69%;
  width: 30.4%;
  top: 34.05%;
  right: 0;
  bottom: 39.26%;
  left: 69.6%;
}
.complereChainsDual,
.selfCustodialAs {
  position: absolute;
  width: 80.71%;
  top: 46.15%;
  left: 19.29%;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.selfCustodialAs {
  top: 0;
  font-size: var(--font-size-9xl);
  line-height: 42px;
  font-weight: 600;
  color: var(--color-white);
}
.fistSvgrepoCom1Icon {
  position: absolute;
  height: 63.33%;
  width: 63.33%;
  top: 18.33%;
  right: 18.33%;
  bottom: 18.33%;
  left: 18.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangleContainer {
  position: absolute;
  height: 28.85%;
  width: 15.23%;
  top: 0;
  right: 84.77%;
  bottom: 71.15%;
  left: 0;
}
.complereChainsDualCustodyParent {
  position: absolute;
  height: 31.9%;
  width: 30.4%;
  top: 68.1%;
  right: 37.11%;
  bottom: 0;
  left: 32.48%;
}
.featuresOfComplereChainParent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  font-size: 17px;
  color: var(--color-lightgray);
  font-family: var(--font-inter);
}

@media only screen and (max-device-width: 440px){
  .featuresOfComplereChainParent{
  width: 410px;
  height: 1000px;
  position: absolute;
  top: calc(50% - 466px);
  left: calc(50% - 205px);
  background: linear-gradient(143.32deg, #041a3b, #10479b);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-smi-8);
  color: var(--color-lightgray);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  }

  .featuresOfComplereContainer{
    width: 70.97%;
    top: 15px;
    left: 14.55%;
    font-size: var(--font-size-5xl);
    line-height: 32px;
    color: var(--color-white);
    text-align: center;
  }
  .featuresOfComplereContainer1{
    width: 100%;
  }
  .span{
    font-weight: 600;
    display: block;
  }
  .featuresOf {
    margin: 0;
    font-weight: 500;
  }
  .groupParent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 14.26%;
  width: 310px;
  left:calc(50% - 155px);
  top:105px;
  }
  .rectangleParent{
    position: absolute;
    height: 34.46%;
    width: 15.24%;
    top: 0;
    right: 84.76%;
    bottom: 65.54%;
    left: 0;
  }
  .groupChild{
    position: absolute;
    height: 14.26%;
    width: 99.65%;
    top: 65.27%;
    right: 0.35%;
    bottom: 20.47%;
    left: 0;
  }
  .groupChild{
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--br-7xs-7);
    background: linear-gradient(
      180deg,
      rgba(250, 88, 182, 0.13),
      rgba(249, 112, 192, 0.25)
    );
  }
  .regulationSvgrepoCom1Icon{
    position: absolute;
    height: 79.91%;
    width: 79.91%;
    top: 10.05%;
    right: 10.05%;
    bottom: 10.05%;
    left: 10.05%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .complereChainPrioritizes,
  .regulationCompliant{
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
  }
  .regulationCompliant{
    height: 34.46%;
    top: 0;
    font-size: var(--font-size-xl);
    font-weight: 600;
    color: var(--color-white);
  }
  .complereChainPrioritizesRegParent{
    position: absolute;
    height: 100%;
    width: 80.7%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 19.3%;
  }

  .complereChainPrioritizes{
    top: 35.59%;
  line-height: 19.95px;
  font-size: 13px;
  }
  .groupContainer{
    position: absolute;
    height: 14.26%;
    width: 310px;
    top: 25.87%;
    right: 0.35%;
    bottom: 55.87%;
    left: calc(50% - 155px);
  }
  .groupItem{
    position: absolute;
  height: 100%;
  width: 80.7%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 19.3%;
  }
  .groupItem{
    height: 34.46%;
  width: 15.24%;
  right: 84.76%;
  bottom: 65.54%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  }
  .groupDiv{
    position: absolute;
    height: 14.26%;
    width: 310px;
    top: 42.27%;
    right: 0.35%;
    bottom: 20.47%;
    left:calc(50% - 155px);
  }
  .groupParent1{
    height: 14.26%;
    width: 310px;
    top: 57.57%;
    right: 0.35%;
    bottom: 38.17%;
    left:calc(50% - 155px);
  }
  .accountSettingsSvgrepoCom1Icon{
    position: absolute;
    height: 69.86%;
    width: 69.86%;
    top: 14.95%;
    right: 15.19%;
    bottom: 15.19%;
    left: 14.95%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  
  .complereChainsDualCustodyParent {
    position: absolute;
    height: 17.04%;
    width: 310px;
    top: 72.96%;
    right: 0;
    bottom: 0;
    left: calc(50% - 155px);
  }

  .groupChild {
    position: absolute;
    height: 14.26%;
    width: 99.65%;
    top: 65.27%;
    right: 0.35%;
    bottom: 20.47%;
    left: 0;
  }
  .groupChild {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--br-7xs-7);
    background: linear-gradient(
      180deg,
      rgba(250, 88, 182, 0.13),
      rgba(249, 112, 192, 0.25)
    );
  }

  .fistSvgrepoCom1Icon{
    position: absolute;
  height: 63.32%;
  width: 63.32%;
  top: 18.46%;
  right: 18.46%;
  bottom: 18.22%;
  left: 18.22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  }
  .complereChainsDual,
  .selfCustodialAs {
    position: absolute;
    width: 80.7%;
    top: 46.09%;
    left: 19.3%;
    line-height: 19.95px;
    display: flex;
    align-items: center;
    font-size: var(--font-size-xl);
    font-size: 13px;
  }
  .selfCustodialAs {
    top: 0;
    font-size: var(--font-size-xl);
    line-height: 29.93px;
    font-weight: 600;
    color: var(--color-white);
  }

  .groupChild6
  
/* .complereChainsDualCustodyParent */
{
    display :none;
  }
/* .complereChainPrioritizesRegParent,
.groupChild6 {
  position: absolute;
  height: 100%;
  width: 80.7%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 19.3%;
}

.complereChainPrioritizes,
.regulationCompliant {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
}

.complereChainPrioritizes {
  top: 35.59%;
  line-height: 19.95px;
}
  
.featuresOfComplereContainer1 {
  width: 100%;
}
.featuresOfComplereContainer {
  position: absolute;
  width: 70.97%;
  top: 0;
  left: 14.55%;
  font-size: var(--font-size-5xl);
  line-height: 32px;
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
}
.featuresOf {
  margin: 0;
  font-weight: 500;
}
.span{
  font-weight: 600;
} */
}