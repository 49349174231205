.tetherUsdtIcon {
  position: absolute;
  height: 10.67%;
  width: 4.44%;
  top: 44.67%;
  right: 88.47%;
  bottom: 44.67%;
  left: 7.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

@media only screen and (max-device-width: 440px){
  .tetherUsdtIcon {
    display: none;
  }
}