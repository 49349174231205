
.groupIcon,
  .groupIcon1 {
    height: 100%;
    width: 88.92%;
    top: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .groupIcon {
    position: absolute;
    right: 16.08%;
    left: 0;
  }
  .groupIcon1 {
    right: 0;
    left: 4%;
  }
  .c,
  .groupGroup,
  .groupIcon1 {
    position: absolute;
  }
  .groupGroup {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .c {
    height: 58.79%;
    width: 37.03%;
    top: 20.56%;
    left: 31.49%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupChild,
  .groupContainer {
    position: absolute;
    height: 78.18%;
    width: 100%;
    top: 16.53%;
    right: 21.32%;
    bottom: 22.79%;
    left: 5px;
  }
  .groupChild {
    height: 87.76%;
    width: 100%;
    top: 12.24%;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 2px dashed var(--color-gray-200);
    box-sizing: border-box;
  }
  .tickCircleSvgrepoCom1Icon {
    position: absolute;
    height: 24.49%;
    width: 27.91%;
    top: -40px;
    right: 36.05%;
    bottom: 75.51%;
    left: 36.05%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .groupParent {
    position: absolute;
    height: 45.09%;
    width: 19.91%;
    top: 49.54%;
    right: 78.32%;
    bottom: 5.37%;
    left: 1.77%;
    text-align: center;
    font-size: var(--font-size-35xl-7);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
@media only screen and (max-device-width: 1640px){
  .groupIcon,
  .groupIcon1 {
    height: 100%;
    width: 88.92%;
    top: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .groupIcon {
    position: absolute;
    right: 16.08%;
    left: 0;
  }
  .groupIcon1 {
    right: 0;
    left: 4%;
  }
  .c,
  .groupGroup,
  .groupIcon1 {
    position: absolute;
  }
  .groupGroup {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .c {
    height: 58.79%;
    width: 37.03%;
    top: 20.56%;
    left: 31.49%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupChild,
  .groupContainer {
    position: absolute;
    height: 78.18%;
    width: 100%;
    top: 16.53%;
    right: 21.32%;
    bottom: 22.79%;
    left: 5px;
  }
  .groupChild {
    height: 87.76%;
    width: 100%;
    top: 12.24%;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 2px dashed var(--color-gray-200);
    box-sizing: border-box;
  }
  .tickCircleSvgrepoCom1Icon {
    position: absolute;
    height: 24.49%;
    width: 27.91%;
    top: -40px;
    right: 36.05%;
    bottom: 75.51%;
    left: 36.05%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .groupParent {
    position: absolute;
    height: 45.09%;
    width: 19.91%;
    top: 49.54%;
    right: 78.32%;
    bottom: 5.37%;
    left: 1.77%;
    text-align: center;
    font-size: var(--font-size-35xl-7);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
}

@media only screen and (max-device-width: 1440px){
  .groupIcon,
  .groupIcon1 {
    height: 100%;
    width: 88.92%;
    top: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .groupIcon {
    position: absolute;
    right: 16.08%;
    left: 0;
  }
  .groupIcon1 {
    right: 0;
    left: 4%;
  }
  .c,
  .groupGroup,
  .groupIcon1 {
    position: absolute;
  }
  .groupGroup {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .c {
    height: 58.79%;
    width: 37.03%;
    top: 20.56%;
    left: 31.49%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupChild,
  .groupContainer {
    position: absolute;
    height: 78.18%;
    width: 100%;
    top: 16.53%;
    right: 21.32%;
    bottom: 22.79%;
    left: 5px;
  }
  .groupChild {
    height: 87.76%;
    width: 100%;
    top: 12.24%;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 2px dashed var(--color-gray-200);
    box-sizing: border-box;
  }
  .tickCircleSvgrepoCom1Icon {
    position: absolute;
    height: 24.49%;
    width: 27.91%;
    top: -40px;
    right: 36.05%;
    bottom: 75.51%;
    left: 36.05%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .groupParent {
    position: absolute;
    height: 45.09%;
    width: 19.91%;
    top: 49.54%;
    right: 78.32%;
    bottom: 5.37%;
    left: 1.77%;
    text-align: center;
    font-size: var(--font-size-35xl-7);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
}