.groupParent16 {
    position: absolute;
    top: 1654px;
    /* left: calc(50% - 760px); */
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    height:80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 68px;
    font-size: 13px;
    color: var(--color-gray-100);
  }
.frameChild13{
  width: 138.9px;
  position: relative;
  height: 60px;
  left:-50px;
}
.frameChild14 {
  width: 138.9px;
  position: relative;
  height: 60px;
  left:-30px;
}
.frameChild14 {
  width: 145px;
  object-fit: cover;
}
.frameChild80{
  width: 148.9px;
  position: relative;
  height: 60px;
  left:-10px;
}
.frameChild81{
  width: 138.9px;
  position: relative;
  height: 60px;
  left:10px;
}
.frameChild82{
  width: 148.9px;
  position: relative;
  height: 60px;
  left:30px;
}
.frameChild83{
  width: 148.9px;
  position: relative;
  height: 60px;
  left:50px;
}
.frameChild84{
  width: 148.9px;
  position: relative;
  height: 60px;
  left:70px;
}
.frameChild85{
  width: 148.9px;
  position: relative;
  height: 60px;
  left:90px;
}
.frameChild86{
  width: 138.9px;
  position: relative;
  height: 60px;
  left:110px;
}
.clipPathGroupParent {
    width: 369px;
    position: relative;
    height: 60px;
}
.clipPathGroup,
.groupChild10,
.groupChild11 {
  position: absolute;
  height: 99%;
  width: 13.63%;
  top: 0;
  right: 86.37%;
  bottom: 1%;
  left:20px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupChild10,
.groupChild11 {
  height: 26.83%;
  width: 60.95%;
  right: 21.38%;
  bottom: 73.17%;
  /* left: 17.67%; */
  left:65px;
}
.groupChild11 {
  height: 28%;
  width: 82.33%;
  top: 37.17%;
  right: 0;
  bottom: 34.83%;
}
.aGovernmentUndertaking {
  position: absolute;
  height: 23.83%;
  width: 48.08%;
  top: 76.17%;
  left: 16.67%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theSataraDccBankLtdParent {
    width: 202.2px;
    position: relative;
    height: 60px;
    text-align: left;
    font-size: 16.9px;
    font-family: var(--font-roboto-condensed);
    left:30px;
  }
  .theSataraDcc {
    position: absolute;
    width: 65.13%;
    top: 10.83%;
    left: 34.82%;
    line-height: 23.48px;
    display: flex;
    align-items: center;
  }
  .unnamedRemovebgPreview11 {
    position: absolute;
    height: 100%;
    width: 29.67%;
    top: 0;
    right: 70.33%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    opacity: 0.75;
  }
  .layer2Icon {
    width: 204.3px;
    position: relative;
    height: 60px;
    left:80px;
  }
 
  @media only screen and (max-device-width: 1350px)
  {
    .groupParent16 {
      position: absolute;
      top: 834px;
      left: calc(50% - 660px);
      background: linear-gradient(103.94deg, #041a3b, #10479b);
      height:80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 68px;
      font-size: 13px;
      color: var(--color-gray-100);
    }
  }
  
  @media only screen and (max-device-width: 440px){
    .groupParent16{
      position: absolute;
    top: 1315px;
    left: calc(50% - 212px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 68px;
    width: 840px;
    font-size: 13px;
    height:100px;
    color: var(--color-gray-100);
    }

    .frameChild13{
      width: 138.9px;
      position: relative;
      height: 60px;
      left:20px;
        margin-top: 20px;

    }

.frameChild14 {
  width: 225.9px;
  position: relative;
  height: 60px;
  left:30px;
    margin-top: 20px;

}
/* .frameChild14 {
  width: 151px;
  object-fit: cover;
} */
.frameChild80 {
  width: 155.9px;
  position: relative;
  height: 60px;
  left:35px;
  margin-top: 20px;
}
.frameChild81 {
  width: 155.9px;
  position: relative;
  height: 60px;
  left:40px;
    margin-top: 20px;

}
.frameChild82 {
  width: 148.9px;
  position: relative;
  height: 60px;
  left:45px;
    margin-top: 20px;

}
.frameChild83 {
  width: 148.9px;
  position: relative;
  height: 60px;
  left:50px;
    margin-top: 20px;

}
.frameChild84 {
  width: 148.9px;
  position: relative;
  height: 60px;
  left:55px;
    margin-top: 20px;

}
.frameChild85{
  left:80px;
  margin-top: 20px;

}
.clipPathGroupParent {
    width: 369px;
    position: relative;
    height: 60px;
    left: -40px;
}
.clipPathGroup,
.groupChild10,
.groupChild11 {
  position: absolute;
  height: 99%;
  width: 13.63%;
  top: 0;
  right: 86.37%;
  bottom: 1%;

  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupChild10,
.groupChild11 {
  height: 26.83%;
  width: 60.95%;
  right: 21.38%;
  bottom: 73.17%;
  left: 17.67%;
}
.groupChild11 {
  height: 28%;
  width: 82.33%;
  top: 37.17%;
  right: 0;
  bottom: 34.83%;
}
.aGovernmentUndertaking {
  position: absolute;
  height: 23.83%;
  width: 48.08%;
  top: 76.17%;
  left: 16.67%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theSataraDccBankLtdParent {
    width: 202.2px;
    position: relative;
    height: 60px;
    text-align: left;
    font-size: 16.9px;
    font-family: var(--font-roboto-condensed);
    left:-30px;
  }
  .theSataraDcc {
    position: absolute;
    width: 65.13%;
    top: 10.83%;
    left: 34.82%;
    line-height: 23.48px;
    display: flex;
    align-items: center;
  }
  .unnamedRemovebgPreview11 {
    position: absolute;
    height: 100%;
    width: 29.67%;
    top: 0;
    right: 70.33%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    opacity: 0.75;
  }
  .layer2Icon {
    width: 204.3px;
    position: relative;
    height: 60px;
    left:-10px;
  }
  }

 