.bg {
  position: absolute;
  height: 87.5%;
  width: 87.5%;
  top: 6.25%;
  right: 6.25%;
  bottom: 6.25%;
  left: 6.25%;
  border-radius: 56px;
  background-color: var(--color-steelblue);
}
.linkedInIcon {
  position: absolute;
  height: 43.75%;
  width: 46.88%;
  top: 25%;
  right: 25%;
  bottom: 31.25%;
  left: 28.13%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.linkedin {
  width: 32px;
  height: 32px;
}

@media only screen and (max-device-width: 1640px){
  .bg {
    position: absolute;
    height: 87.5%;
    width: 87.5%;
    top: 6.25%;
    right: 6.25%;
    bottom: 6.25%;
    left: 6.25%;
    border-radius: 56px;
    background-color: var(--color-steelblue);
  }
  .linkedInIcon {
    position: absolute;
    height: 43.75%;
    width: 46.88%;
    top: 25%;
    right: 25%;
    bottom: 31.25%;
    left: 28.13%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

}