.bridgeNow {
  position: relative;
  font-weight: 600;
}
.arrowRightCircleSvgrepoComIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.bridgeNowParent {
  position: absolute;
  top: 374px;
  left: 254px;
  border-radius: var(--br-45xl);
  background: linear-gradient(90deg, #f970c0, #c40272);
  width: 230px;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-base);
  cursor: pointer;
}
.omplere {
  color: var(--color-darkslateblue);
}
.complereTxt {
  line-break: anywhere;
  width: 100%;
}
.builtOn,
.complere,
.complereChainsLayer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.complere {
  height: 24.66%;
  width: 81.11%;
  top: 0;
  font-size: var(--font-size-53xl);
  font-weight: 600;
  font-family: var(--font-rubik);
  color: var(--color-hotpink);
}
.builtOn,
.complereChainsLayer {
  line-height: 38px;
}
.complereChainsLayer {
  width: 100%;
  top: 26.46%;
  font-size: 22px;
  color: var(--color-darkslategray);
}
.builtOn {
  width: 46.81%;
  top: 0;
  font-weight: 500;
}
.baseWordmarkBlue1Icon {
  position: absolute;
  height: 83.33%;
  width: 53.19%;
  top: 8.33%;
  right: 0;
  bottom: 8.33%;
  left: 46.81%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.builtOnParent {
  position: absolute;
  height: 8.07%;
  width: 34.45%;
  top: 65.02%;
  right: 65.55%;
  bottom: 26.91%;
  left: 0;
  font-size: var(--font-size-5xl);
  color: var(--color-mediumblue);
  font-family: var(--font-rubik);
}
.startBuildingWrapper {
  position: absolute;
  top: 374px;
  left: 0;
  border-radius: var(--br-45xl);
  background-color: var(--color-darkslateblue);
  width: 230px;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-9xs);
  box-sizing: border-box;
  cursor: pointer;
}
.frameParent {
  position: absolute;
  top: 180px;
  left: 72px;
  width: 614px;
  height: 446px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.tool1{
  margin-left:380px;
  padding-top:900px;
  position:'absolute'
}

@media only screen and (max-device-width: 440px){
  .complereChainsLayer {
    width: 440px;
    left:calc(50% - 220px);
    top: 26.46%;
    font-size: 22px;
    color: var(--color-darkslategray);
  }
  .frameParent {
    position: absolute;
    top: 70px;
    left: calc(50% - 190px);
    width: 440px;
    height: 446px;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-inter);
    padding-left: 20px;
    padding-right: 20px;
  }
  .builtOn{
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }
  .builtOn {
    width: 76.81%;
    top: 25%;
    line-height: 20px;
    font-weight: 500;
    font-size: 20px;
  }
  .bridgeNow {
    position: relative;
    font-weight: 500;
    font-size: 15px;
  }
  .baseWordmarkBlue1Icon {
    position: absolute;
    height: 83.5%;
    width: 53.19%;
    top: 8.5%;
    right: 0;
    bottom: 8%;
    left: 46.81%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .complereChainsLayer {
    width: 85%;
    top: 26.46%;
    font-size: 18px;
    color: var(--color-darkslategray);
    line-height: 28px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }
  .builtOnParent {
    position: absolute;
    height: 9.3%;
    width: 37.66%;
    top: 65.7%;
    right: 62.34%;
    bottom: 0;
    left: 0;
    font-size: 13.3px;
    color: var(--color-mediumblue);
    display: flex;
    justify-content: center;
  }
  .startBuildingWrapper {
    position: absolute;
    top: 374px;
    left: 0;
    border-radius: var(--br-45xl);
    background-color: var(--color-darkslateblue);
    width: 170px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-9xs);
    box-sizing: border-box;
  }
  .bridgeNowParent {
    position: absolute;
    top: 374px;
    left: 204px;
    border-radius: var(--br-45xl);
    background: linear-gradient(90deg, #f970c0, #c40272);
    width: 170px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-9xs);
    box-sizing: border-box;
    gap: var(--gap-base);
  }
  .arrowRightCircleSvgrepoComIcon {
    width: 20px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
}

@media only screen and (max-device-width: 362px){

  .frameParent {
    position: absolute;
    top: 70px;
    left: calc(50% - 160px);
    width: 360px;
    height: 446px;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-inter);
    padding-left: 20px;
    padding-right: 20px;
  }
  .complere {
    height: 24.66%;
    width: 81.11%;
    top: 0;
    font-size: 62px;
    font-weight: 600;
    font-family: var(--font-rubik);
    color: var(--color-hotpink);
  }
  .baseWordmarkBlue1Icon {
    position: absolute;
    height: 83.5%;
    width: 53.19%;
    top: 8.5%;
    right: 0;
    bottom: 8%;
    left: 55.81%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .complereChainsLayer {
    width: 85%;
    top: 26.46%;
    font-size: 16px;
    color: var(--color-darkslategray);
    line-height: 28px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }
  .startBuildingWrapper {
    position: absolute;
    top: 374px;
    left: 0;
    border-radius: var(--br-45xl);
    background-color: var(--color-darkslateblue);
    width: 140px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-9xs);
    box-sizing: border-box;
  }
  .bridgeNowParent {
    position: absolute;
    top: 374px;
    left: 184px;
    border-radius: var(--br-45xl);
    background: linear-gradient(90deg, #f970c0, #c40272);
    width: 140px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-9xs);
    box-sizing: border-box;
    gap: var(--gap-base);
  }
  .bridgeNow {
    position: relative;
    font-weight: 500;
    font-size: 12px;
  }
}