.frameChild {
  position: absolute;
  top: 232.5px;
  left: 0;
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-darkslateblue);
  width: 187.5px;
  height: 67.5px;
}
.pricePrediction {
  margin: 0;
}
.pricePredictionModel {
  position: absolute;
  top: 245.3px;
  left: 27.7px;
  font-weight: 600;
}
.aiMiAlgorithmSvgrepoCom1Icon,
.rectangleParent {
  position: absolute;
  top: 169.5px;
  left: 69.4px;
  width: 48.8px;
  height: 48.8px;
  overflow: hidden;
}
.rectangleParent {
  top: 0;
  left: 880px;
  border-radius: var(--br-xs);
  width: 188px;
  height: 300px;
  background-image: url(/public/frame-5@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-mid-3);
  color: var(--color-white);
  font-family: var(--font-inter);
}
