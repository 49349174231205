.mobFooterContent,
.mobItem,
.moblogo,
.moblowfoot,
.discordIconmob,
.hamburgerMenuSvgrepoCom1Icon,
.mobnavlogo
{
    display: none ;
}
.paradiv{
  width:69%;
  float:right;
  padding-left:5px;
  padding-right:20px;
}
.introBlog{
  text-align: left;
  font-family: inter;
  font-weight: 400;
  font-size: 40px;
  color: #ffff;
}
.introPara{
  text-align: left;
  font-family: inter;
  font-size:16px;
  color:#ffff
}
.groupChild {
  /* position: absolute; */
  height: 11.49%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 88.51%;
  left: 0;
  backdrop-filter: blur(24px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.1)
  );
}
.nav{
  height:70px;
  width:95%;
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.footer{
  position:absolute; 
  top:-30%;
  width:700px;
  /* height:100px;  */
   left:calc(50% - 350px);
}
.contentNavigation{
  width:15%;
  height:160px;
  position:fixed;
  left:60px;
  border-radius:10px;
  top:150px;
  background-color:#F7F7F7;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 5px;
  
}
.contentNavigationText{
  text-align:left;
  font-family: inter;
  cursor: pointer;
}
.subcontentNavigation{
  width:75%;
  /* background-color:pink; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}
.contentPara{
  text-align:justify;
  font-family: inter;
  font-size:18px;
  margin-top: 30px;
  letter-spacing: 0.2px;
}
.footerText1{
    color:#ffff;
  font-family: inter;
  cursor: pointer;
}
.footerText2{
    color:#ffff;
  font-family: inter;
  cursor: pointer;
}
.dropdownEco{
  /* position: absolute; */
  top: 0;
  left: -10px;
  width: 107px;
  height: 24px;
  cursor: pointer;
}
.bridge {
  /* position: absolute; */
  top: 0;
  left: 0;
  font-weight: 500;
  cursor: pointer;
}
.groupIcon {
  height: 100%;
  width: 84%;
  top: 0;
  right: 16%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c,
.groupIcon,
.groupIcon1 {
  /* position: absolute; */
}
.groupIcon1 {
  height: 100%;
  width: 84%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 16%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c {
  height: 58.81%;
  width: 37%;
  top: 20.48%;
  left: 31.6%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complere,
.groupContainer {
  /* position: absolute; */
  height: 100%;
  top: 0;
}
.groupContainer {
  width: 22.57%;
  right: 77.43%;
  bottom: 0;
  left: 0;
}
.complere {
  width: 74.27%;
  left: 25.73%;
  font-size: var(--font-size-16xl);
  font-weight: 600;
  color: var(--color-darkslateblue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupParent,
.groupWrapper {
  /* position: absolute; */
  height: 100%;
  width: 1440px;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(50% - 720px);
}
.groupWrapper {
  height: 6.03%;
  width: 15.38%;
  top: 0%;
  right: 79.62%;
  bottom: 91.24%;
  left: 5%;
}
.bridge {
  /* position: absolute; */
  top: 0;
  left: 0;
  font-weight: 500;
}
.arrowUpRightFromSquareSvgIcon {
  /* position: absolute; */
  top: 0;
  left: 71px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.bridgeParent {
  /* position: absolute; */
  top: 0;
  left: 397px;
  width: 95px;
  height: 24px;
  color: var(--color-mediumvioletred);
}
.dropdownArrowSvgrepoCom1Icon {
  position: absolute;
  top: 0;
  left: 63px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.dropdownArrowSvgrepoCom1Parent,
.dropdownArrowSvgrepoCom2Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 87px;
  height: 24px;
}
.dropdownArrowSvgrepoCom2Icon {
  left: 56px;
  width: 24px;
  overflow: hidden;
}
.dropdownArrowSvgrepoCom2Parent,
.dropdownArrowSvgrepoCom3Icon {
  position: absolute;
  top: 0;
  left: 129px;
  width: 80px;
  height: 24px;
}
.dropdownArrowSvgrepoCom3Icon {
  left: 80px;
  width: 24px;
  overflow: hidden;
}
.dropdownArrowSvgrepoCom3Parent,
.groupDiv {
  position: absolute;
  top: 0;
  left: 251px;
  width: 104px;
  height: 24px;
}
.dropdownArrowSvgrepoCom3Parent4{
  position: absolute;
  top: 0;
  left: -145px;
  width: 104px;
  height: 24px;
}
.groupDiv {
  /* top: 28px; */
  left: 876px;
  width: 492px;
  font-size: var(--font-size-xl);
  color: var(--color-darkslateblue);
}
.groupItem {
  position: absolute;
  top: 100px;
  left: 1049px;
  border-radius: var(--br-5xl);
  width: 389px;
  height: 77px;
}
.groupInner {
  top: 614px;
  left: 0;
  width: 1065px;
  height: 82px;
  object-fit: contain;
}
.groupChild1,
.groupInner,
.vectorIcon {
  /* position: absolute; */
  border-radius: var(--br-5xl);
}
.vectorIcon {
  top: 381px;
  left: 540px;
  width: 270px;
  height: 184px;
}
.groupChild1 {
  top: 412px;
  left: 1232px;
  max-height: 100%;
  width: 207px;
}
.rectangleParent {
  /* position: absolute; */
  height: 91.58%;
  width: 100%;
  top: 3.42%;
  right: 0;
  bottom: 5%;
  left: 0;
  text-align: center;
  font-size: var(--font-size-lg-5);
  color: var(--color-hotpink);
  font-family: var(--font-inter);
}
.desktop1 {
  width: 100%;
  display: block;
  /* background-color: blue; */
  /* flex-direction: column; */
  /* position: relative; */
  background-color: var(--color-white);
  height: 100vh;
  /* overflow: hidden; */
}
.groupParent {
  top: 0;
  /* left: calc(50% - 720px); */
  background-color: var(--color-white);
  width: 1440px;
  height: 60px;
  margin-top: 10px;
}
.navtitle{
  display: flex;
  align-items: center;
}
.navSub{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-right: 40px;
  align-items: center;
}
.navText{
  font-size: 20px;
  font-family: inter;
  color:  var(--color-darkslateblue);
  font-weight: 500;
}
.navText2{
  font-size: 20px;
  font-family: inter;
  color:  var(--color-mediumvioletred);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.navText3{
  font-size: 20px;
  font-family: inter;
  color:  var(--color-darkslateblue);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.dropdownArrowSvgrepoCom1Icn{
  width: 24px;
  height: 24px;
}
.arrowUpRightFromSquareSvgIcn{
  width: 24px;
  height: 24px;
}
.content{
  line-height: 42px;
  font-size: 21px;
  width:70%;
}
.contentText{
  justify-content: right;
  display: flex;
  background: linear-gradient(143.32deg, #041a3b, #10479b);
  height:260px;
}
.logoImg{
  width:980px;
  height:480px;
}
.footerContent{
  height: 355px;
  background: linear-gradient(143.32deg, #041a3b, #10479b);
  font-size: var(--font-size-sm);
  color: var(--color-lightgray);
  width:100%;
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: -180px;
}
.blogOuter{
width:100%;
display: flex;
justify-content: center;
}
.bloglist{
    width:80%;
    height:65vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.blogrow{
    display: flex;
    flex-direction: row;
    gap: 40px;
    width:90%;
    margin-top: 10px;
}
.card{
    width:100%;
    height:65%;
    border: solid grey;
    border-width: 1px;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
}
.cardImg{
    width:100%;
    background-color: darkblue;
    height:50%;
    border-radius: 15px;
}
.displayimg{
    width:100%;
    height:100%;
    border-radius: 10px;

}
.footerEle{
  width:90%;
  /* background-color: skyblue; */
  margin-top: -70px;
  height:90%;
  display: flex;
  justify-content: space-between;
}
.footerLeft{
  margin-top: 120px;
  width:50%;
  height:50%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
}
.footerRight{
  margin-top: 180px;
  display: flex;
  flex-direction: row;
  gap:20px;
}
.wwwcomplerexyz{
  font-size: 19px;
  font-family: inter;
  color: #ffff;
}
.footerLHalf{
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.footerLHalf2{
  display: flex;
  flex-direction: column;
  gap: 29px;
  margin-left: 55px;
  margin-top: 5px;
}
.footerFeature{
  font-size: 22px;
  font-family: inter;
  color: #ffff;
}
.discordIcon{
  width:40px;
  height:40px
}
.footerLow{
  display: flex;
  width:90%;
  flex-direction: row;
  justify-content: space-between;
}
.footerLowRight{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.footerText{
  color:#ffff;
  font-family: inter;
  cursor: pointer;
}
.learnMenu{
  width: 160px;
  z-index: 2;
  background-color: #092e66;
  /* background: transparent; */
  left:calc(75% - 0px);
  height:120px;
  top:3.2%;
  position:absolute;
  border: 1px;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  &:hover {
    /* box-shadow: 0 4px 1rem -4px #fa58b6; */
    transform: translateY(-3px);
  }
}

.learnItem{
  color:#ffff;
  /* margin-top: -10px; */
  text-align: center;

}
.learnItem2{
  color:#ffff;
  margin-top: -10px;
  text-align: center;
}
.ham,
.hamMenu {
display: none;
}

  .ham,
  .hamMenu {
  display: none;
  }
  .mobFooterContent,
    .mobItem,
    .moblogo,
    .moblowfoot,
    .discordIconmob{
        display: none !important;;
    }
  
  @media only screen and (max-device-width: 1840px){  
    .mobFooterContent,
    .mobItem,
    .moblogo,
    .moblowfoot,
    .discordIconmob{
        display: none;
    }
    .paradiv{
      width:69%;
      float:right;
      padding-left:5px;
      padding-right:20px;
    }
    .introBlog{
      text-align: left;
      font-family: inter;
      font-weight: 400;
      font-size: 40px;
      color: #ffff;
    }
    .introPara{
      text-align: left;
      font-family: inter;
      font-size:16px;
      color:#ffff
    }
    .groupChild {
      position: absolute;
      height: 11.49%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 88.51%;
      left: 0;
      backdrop-filter: blur(24px);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.1)
      );
    }
    .footer{
      position:absolute; 
      top:15%;
      width:700px;
      /* height:100px;  */
       left:calc(50% - 350px);
    }
    .footerText1{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .footerText2{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .dropdownEco{
      position: absolute;
      top: 0;
      left: -10px;
      width: 107px;
      height: 24px;
      cursor: pointer;
    }
    .bridge {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 500;
      cursor: pointer;
    }
    .groupIcon {
      height: 100%;
      width: 84%;
      top: 0;
      right: 16%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c,
    .groupIcon,
    .groupIcon1 {
      position: absolute;
    }
    .groupIcon1 {
      height: 100%;
      width: 84%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 16%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c {
      height: 58.81%;
      width: 37%;
      top: 20.48%;
      left: 31.6%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .complere,
    .groupContainer {
      position: absolute;
      height: 100%;
      top: 0;
    }
    .groupContainer {
      width: 22.57%;
      right: 77.43%;
      bottom: 0;
      left: 0;
    }
    .complere {
      width: 74.27%;
      left: 25.73%;
      font-size: var(--font-size-16xl);
      font-weight: 600;
      color: var(--color-darkslateblue);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .groupParent,
    .groupWrapper {
      position: absolute;
      height: 100%;
      width: 1440px;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% - 720px);
    }
    .groupWrapper {
      height: 6.03%;
      width: 15.38%;
      top: 0%;
      right: 79.62%;
      bottom: 91.24%;
      left: -3%;
    }
    .bridge {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 500;
    }
    .arrowUpRightFromSquareSvgIcon {
      position: absolute;
      top: 0;
      left: 71px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .bridgeParent {
      position: absolute;
      top: 0;
      left: 397px;
      width: 95px;
      height: 24px;
      color: var(--color-mediumvioletred);
    }
    .dropdownArrowSvgrepoCom1Icon {
      position: absolute;
      top: 0;
      left: 63px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom1Parent,
    .dropdownArrowSvgrepoCom2Icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 87px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom2Icon {
      left: 56px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom2Parent,
    .dropdownArrowSvgrepoCom3Icon {
      position: absolute;
      top: 0;
      left: 129px;
      width: 80px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Icon {
      left: 80px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom3Parent,
    .groupDiv {
      position: absolute;
      top: 0;
      left: 251px;
      width: 104px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Parent4{
      position: absolute;
      top: 0;
      left: -145px;
      width: 104px;
      height: 24px;
    }
    .groupDiv {
      /* top: 28px; */
      left: 876px;
      width: 492px;
      font-size: var(--font-size-xl);
      color: var(--color-darkslateblue);
    }
    .groupItem {
      position: absolute;
      top: 100px;
      left: 1049px;
      border-radius: var(--br-5xl);
      width: 389px;
      height: 77px;
    }
    .groupInner {
      top: 614px;
      left: 0;
      width: 1065px;
      height: 82px;
      object-fit: contain;
    }
    .groupChild1,
    .groupInner,
    .vectorIcon {
      position: absolute;
      border-radius: var(--br-5xl);
    }
    .vectorIcon {
      top: 381px;
      left: 540px;
      width: 270px;
      height: 184px;
    }
    .groupChild1 {
      top: 412px;
      left: 1232px;
      max-height: 100%;
      width: 207px;
    }
    .rectangleParent {
      position: absolute;
      height: 91.58%;
      width: 100%;
      top: 3.42%;
      right: 0;
      bottom: 5%;
      left: 0;
      text-align: center;
      font-size: var(--font-size-lg-5);
      color: var(--color-hotpink);
      font-family: var(--font-inter);
    }
    /* .desktop1 {
      width: 100%;
      position: relative;
      background-color: var(--color-white);
      height: 3015px;
      overflow: hidden;
    } */
    /* .groupParent {
      top: 0;
      left: calc(50% - 720px);
      background-color: var(--color-white);
      width: 1440px;
      height: 760px;
    } */
    .policyText{
      position:absolute;
      top:12%;
      left:calc(50% - 755px);
      /* display: flex;
      justify-content: center; */
      line-height: 50px;
      font-size: 28px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      letter-spacing: 0px;
      width:78%;
      /* margin-bottom: -100px; */
    }
    .policyText2{
      display:none;
      /* margin-bottom: -100px; */
    }
    .bold{
      font-family: inter;
      margin-top: 18px;
    }
    .bold1{
      margin-top: 78px;
    }
    
    .learnMenu{
      width: 160px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(66% - 0px);
      height:120px;
      top:5%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    
      &:hover {
        /* box-shadow: 0 4px 1rem -4px #fa58b6; */
        transform: translateY(-3px);
      }
    }
    
    .learnItem{
      color:#ffff;
      /* margin-top: -10px; */
    }
    .learnItem2{
      color:#ffff;
      margin-top: -10px;
    }
    .ham,
    .hamMenu {
    display: none;
    }
    }
  @media only screen and (max-device-width: 1640px){  
    .mobFooterContent,
    .mobItem,
    .moblogo,
    .moblowfoot,
    .discordIconmob
    {
        display: none ;
    }
    .paradiv{
      width:100%;
      /* float:right; */
      padding-left:5px;
      padding-right:20px;
      position: relative;
      /* z-index: 1; */
      display: flex;
      justify-content: space-around;
      gap: 120px;
      margin-top: 10px;
    }
    .introBlog{
      text-align: left;
      font-family: inter;
      font-weight: 400;
      font-size: 40px;
      color: #ffff;
    }
    .introPara{
      text-align: left;
      font-family: inter;
      font-size:16px;
      color:#ffff
    }
    .groupChild {
      /* position: absolute; */
      height: 11.49%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 88.51%;
      left: 0;
      backdrop-filter: blur(24px);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.1)
      );
    }
    .nav{
      height:70px;
      width:95%;
      background-color: #ffff;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    .footer{
      position:absolute; 
      top:-30%;
      width:700px;
      /* height:100px;  */
       left:calc(50% - 350px);
    }
    .contentNavigation{
      width:16%;
      height:245px;
      position:sticky;
      left:60px;
      border-radius:10px;
      top:150px;
      /* margin-bottom: 150px; */
      background-color:#F7F7F7;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 5px;
    }
    .allPara{
      float: right;
      width:66.5%;
    }
    .contentNavigationText{
      text-align:left;
      font-family: inter;
      cursor: pointer;
    }
    .subcontentNavigation{
      width:75%;
      /* background-color:pink; */
      display: flex;
      /* justify-content: center; */
      /* align-items: center; */
      flex-direction: column;
      gap: 10px;
    }
    .contentPara{
      text-align:justify;
      font-family: inter;
      font-size:18px;
      letter-spacing: 0.2px;
    }
    .footerText1{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .footerText2{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .dropdownEco{
      /* position: absolute; */
      top: 0;
      left: -10px;
      width: 107px;
      height: 24px;
      cursor: pointer;
    }
    .bridge {
      /* position: absolute; */
      top: 0;
      left: 0;
      font-weight: 500;
      cursor: pointer;
    }
    .groupIcon {
      height: 100%;
      width: 84%;
      top: 0;
      right: 16%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c,
    .groupIcon,
    .groupIcon1 {
      /* position: absolute; */
    }
    .groupIcon1 {
      height: 100%;
      width: 84%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 16%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c {
      height: 58.81%;
      width: 37%;
      top: 20.48%;
      left: 31.6%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .complere,
    .groupContainer {
      /* position: absolute; */
      height: 100%;
      top: 0;
    }
    .groupContainer {
      width: 22.57%;
      right: 77.43%;
      bottom: 0;
      left: 0;
    }
    .complere {
      width: 74.27%;
      left: 25.73%;
      font-size: var(--font-size-16xl);
      font-weight: 600;
      color: var(--color-darkslateblue);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .groupParent,
    .groupWrapper {
      /* position: absolute; */
      height: 100%;
      width: 1440px;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% - 720px);
    }
    .groupWrapper {
      height: 6.03%;
      width: 15.38%;
      top: 0%;
      right: 79.62%;
      bottom: 91.24%;
      left: 5%;
    }
    .bridge {
      /* position: absolute; */
      top: 0;
      left: 0;
      font-weight: 500;
    }
    .arrowUpRightFromSquareSvgIcon {
      /* position: absolute; */
      top: 0;
      left: 71px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .bridgeParent {
      /* position: absolute; */
      top: 0;
      left: 397px;
      width: 95px;
      height: 24px;
      color: var(--color-mediumvioletred);
    }
    .dropdownArrowSvgrepoCom1Icon {
      position: absolute;
      top: 0;
      left: 63px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom1Parent,
    .dropdownArrowSvgrepoCom2Icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 87px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom2Icon {
      left: 56px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom2Parent,
    .dropdownArrowSvgrepoCom3Icon {
      position: absolute;
      top: 0;
      left: 129px;
      width: 80px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Icon {
      left: 80px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom3Parent,
    .groupDiv {
      position: absolute;
      top: 0;
      left: 251px;
      width: 104px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Parent4{
      position: absolute;
      top: 0;
      left: -145px;
      width: 104px;
      height: 24px;
    }
    .groupDiv {
      /* top: 28px; */
      left: 876px;
      width: 492px;
      font-size: var(--font-size-xl);
      color: var(--color-darkslateblue);
    }
    .groupItem {
      position: absolute;
      top: 100px;
      left: 1049px;
      border-radius: var(--br-5xl);
      width: 389px;
      height: 77px;
    }
    .groupInner {
      top: 614px;
      left: 0;
      width: 1065px;
      height: 82px;
      object-fit: contain;
    }
    .groupChild1,
    .groupInner,
    .vectorIcon {
      /* position: absolute; */
      border-radius: var(--br-5xl);
    }
    .vectorIcon {
      top: 381px;
      left: 540px;
      width: 270px;
      height: 184px;
    }
    .groupChild1 {
      top: 412px;
      left: 1232px;
      max-height: 100%;
      width: 207px;
    }
    .rectangleParent {
      /* position: absolute; */
      height: 91.58%;
      width: 100%;
      top: 3.42%;
      right: 0;
      bottom: 5%;
      left: 0;
      text-align: center;
      font-size: var(--font-size-lg-5);
      color: var(--color-hotpink);
      font-family: var(--font-inter);
    }
    .desktop1 {
      width: 100%;
      display: block;
      /* background-color: blue; */
      /* flex-direction: column; */
      /* position: relative; */
      background-color: var(--color-white);
      height: 10vh;
      /* overflow: hidden; */
    }
    .groupParent {
      top: 0;
      /* left: calc(50% - 720px); */
      background-color: var(--color-white);
      width: 1440px;
      height: 60px;
      margin-top: 10px;
    }
    .navtitle{
      display: flex;
      align-items: center;
    }
    .navSub{
      display: flex;
      flex-direction: row;
      gap: 40px;
      margin-right: 40px;
      align-items: center;
    }
    .navText{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-darkslateblue);
      font-weight: 500;
    }
    .navText2{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-mediumvioletred);
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .navText3{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-darkslateblue);
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .dropdownArrowSvgrepoCom1Icn{
      width: 24px;
      height: 24px;
    }
    .arrowUpRightFromSquareSvgIcn{
      width: 24px;
      height: 24px;
    }
    .content{
      line-height: 42px;
      font-size: 21px;
      width:70%;
      /* background-color: aqua; */
      float:right;
    }
    .contentText{
      justify-content: right;
      display: flex;
      background: linear-gradient(143.32deg, #041a3b, #10479b);
      height:260px;
    }
    .logoImg{
      width:940px;
      height:480px;
  }
    .footerContent{
      height: 355px;
      background: linear-gradient(143.32deg, #041a3b, #10479b);
      font-size: var(--font-size-sm);
      color: var(--color-lightgray);
      width:100%;
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: -180px;
    }
    .blogOuter{
    width:100%;
    display: flex;
    justify-content: center;
    }
    .bloglist{
        width:80%;
        height:65vh;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
    .blogrow{
        display: flex;
        flex-direction: row;
        gap: 40px;
        width:90%;
        margin-top: 10px;
    }
    .card{
        width:100%;
        height:65%;
        border: solid grey;
        border-width: 1px;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
    }
    .cardImg{
        width:100%;
        background-color: darkblue;
        height:50%;
        border-radius: 15px;
    }
    .displayimg{
        width:100%;
        height:100%;
        border-radius: 10px;

    }
    .footerEle{
      width:90%;
      /* background-color: skyblue; */
      margin-top: -70px;
      height:90%;
      display: flex;
      justify-content: space-between;
    }
    .footerLeft{
      margin-top: 120px;
      width:50%;
      height:50%;
      /* background-color: red; */
      display: flex;
      flex-direction: row;
    }
    .footerRight{
      margin-top: 180px;
      display: flex;
      flex-direction: row;
      gap:20px;
    }
    .wwwcomplerexyz{
      font-size: 19px;
      font-family: inter;
      color: #ffff;
    }
    .footerLHalf{
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .footerLHalf2{
      display: flex;
      flex-direction: column;
      gap: 29px;
      margin-left: 55px;
      margin-top: 5px;
    }
    .footerFeature{
      font-size: 22px;
      font-family: inter;
      color: #ffff;
    }
    .discordIcon{
      width:40px;
      height:40px
    }
    .footerLow{
      display: flex;
      width:90%;
      flex-direction: row;
      justify-content: space-between;
    }
    .footerLowRight{
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .footerText{
      color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .learnMenu{
      width: 160px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(60% - 0px);
      height:120px;
      top:6.5%;
      /* position:absolute; */
      border: 1px;
      border-width: 1px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    
      &:hover {
        /* box-shadow: 0 4px 1rem -4px #fa58b6; */
        transform: translateY(-3px);
      }
    }
    
    .learnItem{
      color:#ffff;
      /* margin-top: -10px; */
      text-align: center;
  
    }
    .learnItem2{
      color:#ffff;
      margin-top: -10px;
      text-align: center;
    }
    .ham,
  .hamMenu,
  .mobnavlogo {
  display: none;
  }
  
  
    }
    @media only screen and (max-device-width: 1440px){
        .mobFooterContent,
        .mobItem,
        .moblogo,
        .moblowfoot,
        .discordIconmob,
        .mobnavlogo{
            display: none !important;;
        }
        .paradiv{
          width:100%;
          /* float:right; */
          padding-left:5px;
          padding-right:20px;
          position: relative;
      /* z-index: 1; */
      display: flex;
      justify-content: space-around;
      gap: 120px;
        }
        .introBlog{
          text-align: left;
          font-family: inter;
          font-weight: 400;
          font-size: 40px;
          color: #ffff;
        }
        .introPara{
          text-align: left;
          font-family: inter;
          font-size:16px;
          color:#ffff
        }
      .groupChild {
        position: absolute;
        height: 11.49%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 88.51%;
        left: 0;
        backdrop-filter: blur(24px);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.1)
        );
      }
      .footer{
        position:absolute;
        /* top:-265%; */
        top:calc(90% - 2920px);
        width:800px;
        height:100px;
        left:calc(50% - 400px);
      }
      .footerText1{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .footerText2{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
      .dropdownEco{
        position: absolute;
        top: 0;
        left: -150px;
        width: 107px;
        height: 24px;
        cursor: pointer;
      }
      .bridge {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 500;
        cursor: pointer;
      }
      .groupIcon {
        height: 100%;
        width: 84%;
        top: 0;
        right: 16%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 84%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.81%;
        width: 37%;
        top: 20.48%;
        left: 31.6%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere,
      .groupContainer {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupContainer {
        width: 22.57%;
        right: 77.43%;
        bottom: 0;
        left: 0;
      }
      .complere {
        width: 74.27%;
        left: 25.73%;
        font-size: var(--font-size-16xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .groupParent,
      .groupWrapper {
        position: absolute;
        height: 100%;
        width: 1440px;
        top: 0;
        right: 0;
        bottom: 0;
        left: calc(50% - 720px);
      }
      .groupWrapper {
        height: 6.03%;
        width: 15.38%;
        top: 0%;
        right: 79.62%;
        bottom: 91.24%;
        left: 5%;
      }
      .bridge {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 500;
      }
      .arrowUpRightFromSquareSvgIcon {
        position: absolute;
        top: 0;
        left: 71px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .bridgeParent {
        position: absolute;
        top: 0;
        left: 397px;
        width: 95px;
        height: 24px;
        color: var(--color-mediumvioletred);
      }
      .dropdownArrowSvgrepoCom3Parent4{
        position: absolute;
        top: 0;
        left: -145px;
        width: 104px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom1Icon {
        position: absolute;
        top: 0;
        left: 63px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom1Parent,
      .dropdownArrowSvgrepoCom2Icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 87px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom2Icon {
        left: 56px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom2Parent,
      .dropdownArrowSvgrepoCom3Icon {
        position: absolute;
        top: 0;
        left: 129px;
        width: 80px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom3Icon {
        left: 80px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom3Parent,
      .groupDiv {
        position: absolute;
        top: 0;
        left: 251px;
        width: 104px;
        height: 24px;
      }
      .groupDiv {
        /* top: 28px; */
        left: 876px;
        width: 492px;
        font-size: var(--font-size-xl);
        color: var(--color-darkslateblue);
      }
      .groupItem {
        position: absolute;
        top: 100px;
        left: 1049px;
        border-radius: var(--br-5xl);
        width: 389px;
        height: 77px;
      }
      .groupInner {
        top: 614px;
        left: 0;
        width: 1065px;
        height: 82px;
        object-fit: contain;
      }
      .groupChild1,
      .groupInner,
      .vectorIcon {
        position: absolute;
        border-radius: var(--br-5xl);
      }
      .vectorIcon {
        top: 381px;
        left: 540px;
        width: 270px;
        height: 184px;
      }
      .groupChild1 {
        top: 412px;
        left: 1232px;
        max-height: 100%;
        width: 207px;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 100%;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: 0;
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      /* .desktop1 {
        width: 100%;
        position: relative;
        background-color: var(--color-white);
        height: 3015px;
        overflow: hidden;
      } */
      /* .groupParent {
        top: 0;
        left: calc(50% - 720px);
        background-color: var(--color-white);
        width: 1440px;
        height: 760px;
      } */
      .policyText{
        position:absolute;
        top:15%;
        left:calc(50% - 615px);
        line-height: 42px;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        /* margin-bottom: -100px; */
      }
      .policyText2{
        display:none;
        /* margin-bottom: -100px; */
      }
      .bold{
        margin-top: 18px;
      }
      .bold1{
        margin-top: 78px;
      }
    
      .learnMenu{
        width: 160px;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        left:calc(58% - 0px);
        height:120px;
        top:6%;
        position:absolute;
        border: 1px;
        border-width: 1px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      
        &:hover {
          /* box-shadow: 0 4px 1rem -4px #fa58b6; */
          transform: translateY(-3px);
        }
      }
      
      .learnItem{
        color:#ffff;
        /* margin-top: -10px; */
      }
      .learnItem2{
        color:#ffff;
        margin-top: -10px;
      }
      .ham,
    .hamMenu {
    display: none;
    }
      }
  @media only screen and (max-device-width: 1380px){
    .mobFooterContent,
    .mobItem,
    .moblogo,
    .moblowfoot,
    .discordIconmob,
    .mobnavlogo{
        display: none;
    }
    .paradiv{
      width:100%;
      /* float:right; */
      padding-left:5px;
      padding-right:20px;
      position: relative;
      /* z-index: 1; */
      display: flex;
      justify-content: space-around;
      gap: 120px;
    }
    .introBlog{
      text-align: left;
      font-family: inter;
      font-weight: 400;
      font-size: 40px;
      color: #ffff;
    }
    .introPara{
      text-align: left;
      font-family: inter;
      font-size:16px;
      color:#ffff
    }
    .groupChild {
      /* position: absolute; */
      height: 11.49%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 88.51%;
      left: 0;
      backdrop-filter: blur(24px);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.1)
      );
    }
    .nav{
      height:70px;
      width:95%;
      background-color: #ffff;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    .footer{
      position:absolute; 
      top:-30%;
      width:700px;
      /* height:100px;  */
       left:calc(50% - 350px);
    }
    .dropdownEco{
      /* position: absolute; */
      top: 0;
      left: -10px;
      width: 107px;
      height: 24px;
      cursor: pointer;
    }
    .bridge {
      /* position: absolute; */
      top: 0;
      left: 0;
      font-weight: 500;
      cursor: pointer;
    }
    .groupIcon {
      height: 100%;
      width: 84%;
      top: 0;
      right: 16%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c,
    .groupIcon,
    .groupIcon1 {
      /* position: absolute; */
    }
    .groupIcon1 {
      height: 100%;
      width: 84%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 16%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c {
      height: 58.81%;
      width: 37%;
      top: 20.48%;
      left: 31.6%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .complere,
    .groupContainer {
      /* position: absolute; */
      height: 100%;
      top: 0;
    }
    .groupContainer {
      width: 22.57%;
      right: 77.43%;
      bottom: 0;
      left: 0;
    }
    .complere {
      width: 74.27%;
      left: 25.73%;
      font-size: var(--font-size-16xl);
      font-weight: 600;
      color: var(--color-darkslateblue);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .groupParent,
    .groupWrapper {
      /* position: absolute; */
      height: 100%;
      width: 1440px;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% - 720px);
    }
    .groupWrapper {
      height: 6.03%;
      width: 15.38%;
      top: 0%;
      right: 79.62%;
      bottom: 91.24%;
      left: 5%;
    }
    .bridge {
      /* position: absolute; */
      top: 0;
      left: 0;
      font-weight: 500;
    }
    .arrowUpRightFromSquareSvgIcon {
      /* position: absolute; */
      top: 0;
      left: 71px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .bridgeParent {
      /* position: absolute; */
      top: 0;
      left: 397px;
      width: 95px;
      height: 24px;
      color: var(--color-mediumvioletred);
    }
    .dropdownArrowSvgrepoCom1Icon {
      position: absolute;
      top: 0;
      left: 63px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom1Parent,
    .dropdownArrowSvgrepoCom2Icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 87px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom2Icon {
      left: 56px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom2Parent,
    .dropdownArrowSvgrepoCom3Icon {
      position: absolute;
      top: 0;
      left: 129px;
      width: 80px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Icon {
      left: 80px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom3Parent,
    .groupDiv {
      position: absolute;
      top: 0;
      left: 251px;
      width: 104px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Parent4{
      position: absolute;
      top: 0;
      left: -145px;
      width: 104px;
      height: 24px;
    }
    .groupDiv {
      /* top: 28px; */
      left: 876px;
      width: 492px;
      font-size: var(--font-size-xl);
      color: var(--color-darkslateblue);
    }
    .groupItem {
      position: absolute;
      top: 100px;
      left: 1049px;
      border-radius: var(--br-5xl);
      width: 389px;
      height: 77px;
    }
    .groupInner {
      top: 614px;
      left: 0;
      width: 1065px;
      height: 82px;
      object-fit: contain;
    }
    .groupChild1,
    .groupInner,
    .vectorIcon {
      /* position: absolute; */
      border-radius: var(--br-5xl);
    }
    .vectorIcon {
      top: 381px;
      left: 540px;
      width: 270px;
      height: 184px;
    }
    .groupChild1 {
      top: 412px;
      left: 1232px;
      max-height: 100%;
      width: 207px;
    }
    .rectangleParent {
      /* position: absolute; */
      height: 91.58%;
      width: 100%;
      top: 3.42%;
      right: 0;
      bottom: 5%;
      left: 0;
      text-align: center;
      font-size: var(--font-size-lg-5);
      color: var(--color-hotpink);
      font-family: var(--font-inter);
    }
    .desktop1 {
      width: 100%;
      display: block;
      /* background-color: blue; */
      /* flex-direction: column; */
      /* position: relative; */
      background-color: var(--color-white);
      height: 10vh;
      /* overflow: hidden; */
    }
    .groupParent {
      top: 0;
      /* left: calc(50% - 720px); */
      background-color: var(--color-white);
      width: 1440px;
      height: 60px;
      margin-top: 10px;
    }
    .navtitle{
      display: flex;
      align-items: center;
    }
    .navSub{
      display: flex;
      flex-direction: row;
      gap: 40px;
      margin-right: 40px;
      align-items: center;
    }
    .navText{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-darkslateblue);
      font-weight: 500;
    }
    .navText2{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-mediumvioletred);
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .navText3{
      font-size: 20px;
      font-family: inter;
      color:  var(--color-darkslateblue);
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .dropdownArrowSvgrepoCom1Icn{
      width: 24px;
      height: 24px;
    }
    .arrowUpRightFromSquareSvgIcn{
      width: 24px;
      height: 24px;
    }
    .content{
      line-height: 42px;
      font-size: 32px;
      width:70%;
    }
    .contentText{
      justify-content: right;
      display: flex;
    }
    .footerContent{
      height: 355px;
      background: linear-gradient(143.32deg, #041a3b, #10479b);
      font-size: var(--font-size-sm);
      color: var(--color-lightgray);
      width:100%;
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: -180px;
    }
    
    .policyText{
      /* position:absolute; */
      top:9%;
      /* left:calc(50% - 615px); */
      line-height: 42px;
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      letter-spacing: 0px;
      width:80%;
      height: auto;
      /* margin-bottom: -100px; */
    }
    .policyText2{
      display:none;
      /* margin-bottom: -100px; */
    }
    .bold{
      margin-top: 18px;
    }
    .bold1{
      margin-top: 78px;
    }
    .footerEle{
      width:90%;
      /* background-color: skyblue; */
      margin-top: -70px;
      height:90%;
      display: flex;
      justify-content: space-between;
    }
    .footerLeft{
      margin-top: 120px;
      width:50%;
      height:50%;
      /* background-color: red; */
      display: flex;
      flex-direction: row;
    }
    .footerRight{
      margin-top: 180px;
      display: flex;
      flex-direction: row;
      gap:20px;
    }
    .wwwcomplerexyz{
      font-size: 19px;
      font-family: inter;
      color: #ffff;
    }
    .footerLHalf{
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .footerLHalf2{
      display: flex;
      flex-direction: column;
      gap: 29px;
      margin-left: 55px;
      margin-top: 5px;
    }
    .footerFeature{
      font-size: 20px;
      font-family: inter;
      color: #ffff;
    }
    .discordIcon{
      width:40px;
      height:40px
    }
    .footerLow{
      display: flex;
      width:90%;
      flex-direction: row;
      justify-content: space-between;
    }
    .footerLowRight{
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .footerText{
      color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .footerText1{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .footerText2{
        color:#ffff;
      font-family: inter;
      cursor: pointer;
    }
    .learnMenu{
      width: 160px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(56% - 0px);
      height:120px;
      top:7.5%;
      /* position:absolute; */
      border: 1px;
      border-width: 1px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    
      &:hover {
        /* box-shadow: 0 4px 1rem -4px #fa58b6; */
        transform: translateY(-3px);
      }
    }
    
    .learnItem{
      color:#ffff;
      /* margin-top: -10px; */
      text-align: center;
    
    }
    .learnItem2{
      color:#ffff;
      margin-top: -10px;
      text-align: center;
    }
    .ham,
    .hamMenu {
    display: none;
    }
    
    }
  
    @media (max-device-width: 440px) and (max-height:900px){
      .groupChild {
        /* position: absolute; */
        height: 11.49%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 88.51%;
        left: 0;
        backdrop-filter: blur(24px);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.1)
        );
      }
      .paradiv{
        width:88%;
        /* float:right;
        padding-left:5px;
        padding-right:20px; */
      }
      .introBlog{
        text-align: center;
        font-family: inter;
        font-weight: 400;
        font-size: 30px;
        color: #ffff;
      }
      .introPara{
        text-align: center;
        font-family: inter;
        font-size:16px;
        color:#ffff
      }
      .contentNavigation{
        display: none;
      }

      .allPara{
        /* float: left; */
        width:100%;
      }
      .contentPara{
        text-align: justify;
        font-family: inter;
        font-size: 18px;
        letter-spacing: 0.2px;
      }
      .logoImg{
        width: 300px;
        height: 150px;
      }
      .nav{
        display: none;
      }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: 5px;
        left: 330px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      .mobnavlogo{
        display: block;
        margin-top: 5px;
      }
      .hamMenu{
        width: 100%;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        /* left:calc(50% - 220px); */
        height:25%;
        top:6%;
        position:absolute;
        border: 1px;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      }
      .menItem{
        color:white;
        text-align: center;
        font-family: inter;
        margin-top: 12px;
      }
      .moblearnMenu{
        width: 100%;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        /* left:calc(50% - 220px); */
        height:15%;
        top:6%;
        position:absolute;
        border: 1px;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      }
      .moblearnItem{
        color:white;
        font-family: inter;
        text-align: center;
      }
      .footer{
        position:absolute; 
        top:-30%;
        width:700px;
        /* height:100px;  */
         left:calc(50% - 350px);
      }
      .dropdownEco{
        /* position: absolute; */
        top: 0;
        left: -10px;
        width: 107px;
        height: 24px;
        cursor: pointer;
      }
      .bridge {
        /* position: absolute; */
        top: 0;
        left: 0;
        font-weight: 500;
        cursor: pointer;
      }
      .groupIcon {
        height: 100%;
        width: 84%;
        top: 0;
        right: 16%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        /* position: absolute; */
      }
      .groupIcon1 {
        height: 100%;
        width: 84%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.81%;
        width: 37%;
        top: 20.48%;
        left: 31.6%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere,
      .groupContainer {
        /* position: absolute; */
        height: 100%;
        top: 0;
      }
      .groupContainer {
        width: 22.57%;
        right: 77.43%;
        bottom: 0;
        left: 0;
      }
      .complere {
        width: 74.27%;
        left: 25.73%;
        font-size: var(--font-size-16xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .groupParent,
      .groupWrapper {
        /* position: absolute; */
        height: 100%;
        width: 1440px;
        top: 0;
        right: 0;
        bottom: 0;
        left: calc(50% - 720px);
      }
      .groupWrapper {
        height: 6.03%;
        width: 15.38%;
        top: 0%;
        right: 79.62%;
        bottom: 91.24%;
        left: 5%;
      }
      .bridge {
        /* position: absolute; */
        top: 0;
        left: 0;
        font-weight: 500;
      }
      .arrowUpRightFromSquareSvgIcon {
        /* position: absolute; */
        top: 0;
        left: 71px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .bridgeParent {
        /* position: absolute; */
        top: 0;
        left: 397px;
        width: 95px;
        height: 24px;
        color: var(--color-mediumvioletred);
      }
      .dropdownArrowSvgrepoCom1Icon {
        position: absolute;
        top: 0;
        left: 63px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom1Parent,
      .dropdownArrowSvgrepoCom2Icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 87px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom2Icon {
        left: 56px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom2Parent,
      .dropdownArrowSvgrepoCom3Icon {
        position: absolute;
        top: 0;
        left: 129px;
        width: 80px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom3Icon {
        left: 80px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom3Parent,
      .groupDiv {
        position: absolute;
        top: 0;
        left: 251px;
        width: 104px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom3Parent4{
        position: absolute;
        top: 0;
        left: -145px;
        width: 104px;
        height: 24px;
      }
      .groupDiv {
        /* top: 28px; */
        left: 876px;
        width: 492px;
        font-size: var(--font-size-xl);
        color: var(--color-darkslateblue);
      }
      .groupItem {
        position: absolute;
        top: 100px;
        left: 1049px;
        border-radius: var(--br-5xl);
        width: 389px;
        height: 77px;
      }
      .groupInner {
        top: 614px;
        left: 0;
        width: 1065px;
        height: 82px;
        object-fit: contain;
      }
      .groupChild1,
      .groupInner,
      .vectorIcon {
        /* position: absolute; */
        border-radius: var(--br-5xl);
      }
      .vectorIcon {
        top: 381px;
        left: 540px;
        width: 270px;
        height: 184px;
      }
      .groupChild1 {
        top: 412px;
        left: 1232px;
        max-height: 100%;
        width: 207px;
      }
      .rectangleParent {
        /* position: absolute; */
        height: 91.58%;
        width: 100%;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: 0;
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .desktop1 {
        width: 100%;
        display: block;
        /* background-color: blue; */
        /* flex-direction: column; */
        /* position: relative; */
        background-color: var(--color-white);
        height: 100vh;
        /* overflow: hidden; */
      }
      .groupParent {
        top: 0;
        /* left: calc(50% - 720px); */
        background-color: var(--color-white);
        width: 1440px;
        height: 60px;
        margin-top: 10px;
      }
      .navtitle{
        display: flex;
        align-items: center;
      }
      .navSub{
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-right: 40px;
        align-items: center;
      }
      .navText{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-darkslateblue);
        font-weight: 500;
      }
      .navText2{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-mediumvioletred);
        font-weight: 500;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
      .navText3{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-darkslateblue);
        font-weight: 500;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
      .dropdownArrowSvgrepoCom1Icn{
        width: 24px;
        height: 24px;
      }
      .arrowUpRightFromSquareSvgIcn{
        width: 24px;
        height: 24px;
      }
      .content{
        line-height: 38px;
        font-size: 22px;
        width:90%;
      }
      .contentText{
        justify-content: center;
        display: flex;
      }
      .about_img{
        width:304px;
      }
      .footerContent{
        display: none;
      }
      .blogOuter{
        width:100%;
        display: flex;
        justify-content: center;
        }
        .bloglist{
            width:80%;
            height:65vh;
            display: flex;
            justify-content: center;
            /* align-items: center; */
        }
        .blogrow{
            display: flex;
            flex-direction: column;
            gap: 40px;
            width:90%;
            margin-top: 10px;
        }
        .card{
            width:100%;
            height:65%;
            border: solid grey;
            border-width: 1px;
            border-radius: 15px;
            padding: 10px;
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
        }
        .cardImg{
            width:100%;
            background-color: darkblue;
            height:50%;
            border-radius: 15px;
        }
        .displayimg{
            width:100%;
            height:100%;
            border-radius: 10px;
    
        }
       
      .mobFooterContent{
        height: 485px;
      background: linear-gradient(143.32deg, #041a3b, #10479b);
      font-size: var(--font-size-sm);
      color: var(--color-lightgray);
      width:100%;
      margin-top: 750px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      }
      .mobHeader{
        margin-top: -10px;
      }
      .moblogo{
        display: flex !important;
        flex-direction: row;
        margin-top: 60px;
        gap: 15px;
      }
      .mobele{
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        margin-top: 20px;
      }
      .mobItem{
        display: flex !important;
        flex-direction: column;
        gap: 20px;
      }
      .mobsubItem{
        color: #ffff;
        font-size: 22px;
        font-family: inter;
      }
      .moblowfoot{
        display: flex !important;
        color: #ffff;
        font-family: inter;
        margin-top: 30px;
      }
      .mobfootbottom{
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        width:90%;
        margin-top: 20px;
      }
      .mobbottom{
        font-family: inter;
        color: #ffff;
      }
      .policyText{
        /* position:absolute; */
        top:9%;
        /* left:calc(50% - 615px); */
        line-height: 42px;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height: auto;
        /* margin-bottom: -100px; */
      }
      .policyText2{
        display:none;
        /* margin-bottom: -100px; */
      }
      .bold{
        margin-top: 18px;
      }
      .bold1{
        margin-top: 78px;
      }
      .footerEle{
        width:90%;
        /* background-color: skyblue; */
        margin-top: -70px;
        height:90%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .footerLeft{
        margin-top: 90px;
        width:100%;
        height:50%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
      }
      .footerRight{
        /* margin-top: -40px;
        display: flex;
        flex-direction: row;
        gap:20px; */
        display: none;
      }
      .wwwcomplerexyz{
        font-size: 21px;
        margin-top: 40px;
        font-family: inter;
        color: #ffff;
      }
      .footerLHalf{
        display: flex;
        flex-direction: column;
        gap: 18px;
        width:100%;
      }
      .footerLHalf2{
        /* display: flex;
        flex-direction: column;
        gap: 29px;
        margin-left: 55px;
        margin-top: 5px; */
        display: none;
      }
      .footerFeature{
        font-size: 20px;
        font-family: inter;
        color: #ffff;
      }
      .discordIconmob{
        display: flex !important;
        width:40px;
        height:40px;
      }
      .footerLow{
        display: flex;
        width:90%;
        flex-direction: column;
        /* justify-content: space-between; */
      }
      .footerLowRight{
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
      .footerText{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
      }
      .footerText1{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
        margin-top: -30px;
      }
      .footerText2{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
        margin-top: 10px;
        width:100%
      }
      .learnMenu{
        width: 160px;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        left:calc(60% - 0px);
        height:120px;
        top:6.5%;
        /* position:absolute; */
        border: 1px;
        border-width: 1px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      
        &:hover {
          /* box-shadow: 0 4px 1rem -4px #fa58b6; */
          transform: translateY(-3px);
        }
      }
      
      .learnItem{
        color:#ffff;
        /* margin-top: -10px; */
        text-align: center;
      
      }
      .learnItem2{
        color:#ffff;
        margin-top: -10px;
        text-align: center;
      }
      .ham,
      .hamMenu {
      display: block;
      }
    }
  
    @media (max-device-width: 440px) and (max-height:700px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }.groupChild {
        /* position: absolute; */
        height: 11.49%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 88.51%;
        left: 0;
        backdrop-filter: blur(24px);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.1)
        );
      }
      .introBlog{
        text-align: center;
        font-family: inter;
        font-weight: 400;
        font-size: 24px;
        color: #ffff;
      }
      .introPara{
        text-align: center;
        font-family: inter;
        font-size:14px;
        color:#ffff
      }
      .contentNavigation{
        display: none;
      }
      .logoImg{
        width: 300px;
        height: 150px;
      }
      .nav{
        display: none;
      }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: 5px;
        left: 330px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      .mobnavlogo{
        display: block;
        margin-top: 5px;
      }
      .hamMenu{
        width: 100%;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        /* left:calc(50% - 220px); */
        height:32%;
        top:8%;
        position:absolute;
        border: 1px;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      }
      .menItem{
        color:white;
        text-align: center;
        font-family: inter;
        margin-top: 12px;
      }
      .moblearnMenu{
        width: 100%;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        /* left:calc(50% - 220px); */
        height:15%;
        top:8%;
        position:absolute;
        border: 1px;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      }
      .moblearnItem{
        color:white;
        font-family: inter;
        text-align: center;
      }
      .footer{
        position:absolute; 
        top:-30%;
        width:700px;
        /* height:100px;  */
         left:calc(50% - 350px);
      }
      .dropdownEco{
        /* position: absolute; */
        top: 0;
        left: -10px;
        width: 107px;
        height: 24px;
        cursor: pointer;
      }
      .bridge {
        /* position: absolute; */
        top: 0;
        left: 0;
        font-weight: 500;
        cursor: pointer;
      }
      .groupIcon {
        height: 100%;
        width: 84%;
        top: 0;
        right: 16%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        /* position: absolute; */
      }
      .groupIcon1 {
        height: 100%;
        width: 84%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.81%;
        width: 37%;
        top: 20.48%;
        left: 31.6%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere,
      .groupContainer {
        /* position: absolute; */
        height: 100%;
        top: 0;
      }
      .groupContainer {
        width: 22.57%;
        right: 77.43%;
        bottom: 0;
        left: 0;
      }
      .complere {
        width: 74.27%;
        left: 25.73%;
        font-size: var(--font-size-16xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .groupParent,
      .groupWrapper {
        /* position: absolute; */
        height: 100%;
        width: 1440px;
        top: 0;
        right: 0;
        bottom: 0;
        left: calc(50% - 720px);
      }
      .groupWrapper {
        height: 6.03%;
        width: 15.38%;
        top: 0%;
        right: 79.62%;
        bottom: 91.24%;
        left: 5%;
      }
      .bridge {
        /* position: absolute; */
        top: 0;
        left: 0;
        font-weight: 500;
      }
      .arrowUpRightFromSquareSvgIcon {
        /* position: absolute; */
        top: 0;
        left: 71px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .bridgeParent {
        /* position: absolute; */
        top: 0;
        left: 397px;
        width: 95px;
        height: 24px;
        color: var(--color-mediumvioletred);
      }
      .dropdownArrowSvgrepoCom1Icon {
        position: absolute;
        top: 0;
        left: 63px;
        width: 24px;
        height: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom1Parent,
      .dropdownArrowSvgrepoCom2Icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 87px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom2Icon {
        left: 56px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom2Parent,
      .dropdownArrowSvgrepoCom3Icon {
        position: absolute;
        top: 0;
        left: 129px;
        width: 80px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom3Icon {
        left: 80px;
        width: 24px;
        overflow: hidden;
      }
      .dropdownArrowSvgrepoCom3Parent,
      .groupDiv {
        position: absolute;
        top: 0;
        left: 251px;
        width: 104px;
        height: 24px;
      }
      .dropdownArrowSvgrepoCom3Parent4{
        position: absolute;
        top: 0;
        left: -145px;
        width: 104px;
        height: 24px;
      }
      .groupDiv {
        /* top: 28px; */
        left: 876px;
        width: 492px;
        font-size: var(--font-size-xl);
        color: var(--color-darkslateblue);
      }
      .groupItem {
        position: absolute;
        top: 100px;
        left: 1049px;
        border-radius: var(--br-5xl);
        width: 389px;
        height: 77px;
      }
      .groupInner {
        top: 614px;
        left: 0;
        width: 1065px;
        height: 82px;
        object-fit: contain;
      }
      .groupChild1,
      .groupInner,
      .vectorIcon {
        /* position: absolute; */
        border-radius: var(--br-5xl);
      }
      .vectorIcon {
        top: 381px;
        left: 540px;
        width: 270px;
        height: 184px;
      }
      .groupChild1 {
        top: 412px;
        left: 1232px;
        max-height: 100%;
        width: 207px;
      }
      .rectangleParent {
        /* position: absolute; */
        height: 91.58%;
        width: 100%;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: 0;
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .desktop1 {
        width: 100%;
        display: block;
        /* background-color: blue; */
        /* flex-direction: column; */
        /* position: relative; */
        background-color: var(--color-white);
        height: 100vh;
        /* overflow: hidden; */
      }
      .groupParent {
        top: 0;
        /* left: calc(50% - 720px); */
        background-color: var(--color-white);
        width: 1440px;
        height: 60px;
        margin-top: 10px;
      }
      .navtitle{
        display: flex;
        align-items: center;
      }
      .navSub{
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-right: 40px;
        align-items: center;
      }
      .navText{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-darkslateblue);
        font-weight: 500;
      }
      .navText2{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-mediumvioletred);
        font-weight: 500;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
      .navText3{
        font-size: 20px;
        font-family: inter;
        color:  var(--color-darkslateblue);
        font-weight: 500;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
      .dropdownArrowSvgrepoCom1Icn{
        width: 24px;
        height: 24px;
      }
      .arrowUpRightFromSquareSvgIcn{
        width: 24px;
        height: 24px;
      }
      .content{
        line-height: 42px;
        font-size: 22px;
        width:70%;
      }
      .contentText{
        justify-content: center;
        display: flex;
      }
      .about_img{
        width:304px;
      }
      .footerContent{
        display: none;
      }
      .blogOuter{
        width:100%;
        display: flex;
        justify-content: center;
        }
        .bloglist{
            width:80%;
            height:65vh;
            display: flex;
            justify-content: center;
            /* align-items: center; */
        }
        .blogrow{
            display: flex;
            flex-direction: column;
            gap: 40px;
            width:90%;
            margin-top: 10px;
        }
        .card{
            width:100%;
            height:80%;
            border: solid grey;
            border-width: 1px;
            border-radius: 15px;
            padding: 10px;
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
        }
        .cardImg{
            width:100%;
            background-color: darkblue;
            height:50%;
            border-radius: 15px;
        }
        .displayimg{
            width:100%;
            height:100%;
            border-radius: 10px;
    
        }
       
      .mobFooterContent{
      height: 485px;
      background: linear-gradient(143.32deg, #041a3b, #10479b);
      font-size: var(--font-size-sm);
      color: var(--color-lightgray);
      width:100%;
      margin-top: 750px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      }
      .mobHeader{
        margin-top: -10px;
      }
      .moblogo{
        display: flex !important;
        flex-direction: row;
        margin-top: 60px;
        gap: 15px;
      }
      .mobele{
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        margin-top: 20px;
      }
      .mobItem{
        display: flex !important;
        flex-direction: column;
        gap: 20px;
      }
      .mobsubItem{
        color: #ffff;
        font-size: 22px;
        font-family: inter;
      }
      .moblowfoot{
        color: #ffff;
        font-family: inter;
        margin-top: 30px;
        display: block !important;
      }
      .mobfootbottom{
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        width:90%;
        margin-top: 20px;
      }
      .mobbottom{
        font-family: inter;
        color: #ffff;
      }
      .policyText{
        /* position:absolute; */
        top:9%;
        /* left:calc(50% - 615px); */
        line-height: 42px;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height: auto;
        /* margin-bottom: -100px; */
      }
      .policyText2{
        display:none;
        /* margin-bottom: -100px; */
      }
      .bold{
        margin-top: 18px;
      }
      .bold1{
        margin-top: 78px;
      }
      .footerEle{
        width:90%;
        /* background-color: skyblue; */
        margin-top: -70px;
        height:90%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .footerLeft{
        margin-top: 90px;
        width:100%;
        height:50%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
      }
      .footerRight{
        /* margin-top: -40px;
        display: flex;
        flex-direction: row;
        gap:20px; */
        display: none;
      }
      .wwwcomplerexyz{
        font-size: 21px;
        margin-top: 40px;
        font-family: inter;
        color: #ffff;
      }
      .footerLHalf{
        display: flex;
        flex-direction: column;
        gap: 18px;
        width:100%;
      }
      .footerLHalf2{
        /* display: flex;
        flex-direction: column;
        gap: 29px;
        margin-left: 55px;
        margin-top: 5px; */
        display: none;
      }
      .footerFeature{
        font-size: 20px;
        font-family: inter;
        color: #ffff;
      }
      .discordIconmob{
        display: block !important;
        width:40px;
        height:40px
      }
      .footerLow{
        display: flex !important;
        width:90%;
        flex-direction: column;
        /* justify-content: space-between; */
      }
      .footerLowRight{
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
      .footerText{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
      }
      .footerText1{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
        margin-top: -30px;
      }
      .footerText2{
        color:#ffff;
        font-family: inter;
        cursor: pointer;
        margin-top: 10px;
        width:100%
      }
      .learnMenu{
        width: 160px;
        z-index: 2;
        background-color: #092e66;
        /* background: transparent; */
        left:calc(60% - 0px);
        height:120px;
        top:6.5%;
        /* position:absolute; */
        border: 1px;
        border-width: 1px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
      
        &:hover {
          /* box-shadow: 0 4px 1rem -4px #fa58b6; */
          transform: translateY(-3px);
        }
      }
      
      .learnItem{
        color:#ffff;
        /* margin-top: -10px; */
        text-align: center;
      
      }
      .learnItem2{
        color:#ffff;
        margin-top: -10px;
        text-align: center;
      }
      .ham,
      .hamMenu {
      display: block;
      }
    }
  
    @media only screen and (max-device-width: 365px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 360px;
        top: 8.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 180px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .policyText{
        position:absolute;
        top:10%;
        left:calc(50% - 150px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:100%
      }
      .policyText2{
        position:absolute;
        top:180%;
        left:calc(50% - 170px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        /* margin-bottom: -100px; */
      }
      .footer{
        position:absolute;
        top:49%;
        width:100%;
        left:calc(50% - 155px);
      }
      .about_img{
        width:304px;
      }
      .ham,
      .hamMenu {
        display: block;
        }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: 5px;
        left: 310px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      
    .hamMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:36%;
      top:8%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .menItem{
      color:white;
      text-align: center;
      font-family: inter;
      margin-top: 2px;
    }
    .moblearnMenu{
      width: 100%;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      /* left:calc(50% - 220px); */
      height:15%;
      top:8%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .moblearnItem{
      color:white;
      font-family: inter;
      text-align: center;
    }
      .groupChild{
        position: absolute;
        top: -50px;
        left: calc(50% - 180px);
        box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
        background-color: var(--color-white);
        width: 420px;
        height: 54px;
      }
      .groupWrapper{
        position: absolute;
      height: 0.71%;
      width: 35.17%;
      top: -7.44%;
      right: 58.17%;
      bottom: 98.85%;
      left: 2.67%;
      font-size: var(--font-size-2xs-6);
      color: var(--color-hotpink);
      }
      .complere,
      .groupContainer,
      .groupParent {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupParent {
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .groupContainer {
        width: 22.59%;
        right: 77.41%;
        bottom: 0;
        left: 0;
      }
      .groupIcon {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 16.08%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16.08%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.75%;
        width: 37.06%;
        top: 20.42%;
        left: 31.47%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere {
        width: 74.25%;
        left: 25.75%;
        font-size: var(--font-size-xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }