.frameChild,
.frameItem {
  position: absolute;
  border-radius: 50%;
  border: 1px dashed var(--color-gray-100);
  box-sizing: border-box;
}
.frameChild {
  top: -149px;
  left: 134px;
  width: 1172px;
  height: 898px;
}
.frameItem {
  top: calc(50% - 380px);
  left: calc(50% - 496px);
  width: 992.2px;
  height: 760px;
}
.frameInner {
  position: absolute;
  height: 39.67%;
  width: 71.39%;
  top: 30.17%;
  right: 14.31%;
  bottom: 30.17%;
  left: 14.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.bridgeNow {
  position: relative;
  font-weight: 600;
}
.arrowRightCircleSvgrepoComIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.bridgeNowParent,
.useANative {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bridgeNowParent {
  top: 286px;
  left: 264px;
  border-radius: var(--br-45xl);
  background: linear-gradient(90deg, #f970c0, #c40272);
  width: 230px;
  height: 72px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  cursor: pointer;
}
.useANative {
  width: 100%;
  top: 36.31%;
  left: 0;
  font-size: 22px;
  line-height: 36px;
  color: var(--color-darkslategray);
}
.startUsing {
  font-weight: 600;
  color: var(--color-darkslateblue);
}
.c,
.startUsing {
  font-family: var(--font-rubik);
}
.c,
.omplere {
  font-weight: 600;
}
.startUsingComplere {
  margin: 0;
}
.byBridgingToTheNetwork {
  margin: 0;
  color: var(--color-darkslateblue);
}
.startUsingComplereContainer1 {
  width: 100%;
}
.startUsingComplereContainer {
  position: absolute;
  top: 0;
  left: calc(50% - 280px);
  font-size: var(--font-size-23xl);
  line-height: 56px;
  display: flex;
  align-items: center;
  width: 560px;
  color: var(--color-hotpink);
  font-family: var(--font-rubik);
}
.frameParent,
.groupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 758px;
  height: 358px;
}
.groupWrapper {
  top: 121px;
  left: 341px;
}
.ellipseParent {
  position: absolute;
  top: 2560px;
  left: calc(50% - 720px);
  background-color: var(--color-white);
  width: 1440px;
  height: 600px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

@media only screen and (max-device-width: 440px){
  .ellipseParent {
    display: none;
  }
  .frameParent,
.groupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 440px;
  height: 358px;
}
.groupWrapper {
  top: 121px;
  left: calc(50% - 220px);
  width:440px
}
  .ellipseParent {
    position: absolute;
    top: 2420px;
    left: calc(50% - 220px);
    background-color: var(--color-white);
    /* //var(--color-white); */
    background-image: url(/public/ellipse-bg.png);
    background-size: 425px;
    background-repeat: no-repeat;
    background-position: center;
    /* background-position-x: -1px; */
    width: 440px;
    height: 950px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-inter);
  }
  .frameItem {
    top: calc(50% - 280px);
    left: calc(50% - 496px);
    width: 492.2px;
    height: 760px;
  }
  .frameInner {
    position: absolute;
    height: 39.67%;
    width: 40.39%;
    top: 30.17%;
    right: 14.31%;
    bottom: 30.17%;
    left: 14.31%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .useANative {
    width: 300px;
    top: 75.31%;
    left: calc(50% - 150px);
    font-size: 16px;
    line-height: 34px;
    color: var(--color-darkslategray);
  }
  .startUsingComplere {
    display: block;
    justify-content: center;
  }
  .startUsing {
    font-weight: 400;
    color: var(--color-darkslateblue);
  }
  .c,
  .startUsing {
    font-family: var(--font-rubik);
    font-size: 26px;

  }
  .c,
  .omplere {
    font-weight: 500;
    font-size: 26px;
  }
  
  .startUsingComplereContainer {
    position: absolute;
    top: 170px;
    left: calc(50% - 220px);
    font-size: var(--font-size-23xl);
    line-height: 56px;
    display: flex;
    align-items: center;
    width: 440px;
    color: var(--color-hotpink);
    font-family: var(--font-rubik);
  }
  .startUsingComplereContainer1 {
    left: calc(50% - 220px);
    position: absolute;
    width: 440px;
  }
  .bridgeNowParent,
.useANative {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bridgeNowParent {
  top: 456px;
  left: calc(50% - 115px);
  border-radius: var(--br-45xl);
  background: linear-gradient(90deg, #f970c0, #c40272);
  width: 230px;
  height: 72px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  cursor: pointer;
}
  .frameChild,
  .frameItem {
    display: none;
  }
}