@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,500;0,600;1,500;1,600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-rubik: Rubik;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-11xl: 30px;
  --font-size-base-9: 15.9px;
  --font-size-base: 16px;
  --font-size-23xl: 42px;
  --font-size-5xl: 24px;
  --font-size-35xl-7: 54.7px;
  --font-size-9xl: 28px;
  --font-size-mid-3: 17.3px;
  --font-size-45xl: 64px;
  --font-size-53xl: 72px;
  --font-size-16xl: 35px;
  --font-size-lg-5: 18.5px;

  /* Colors */
  --color-white: #fff;
  --color-lightgray: #d2d2d2;
  --color-steelblue: #1275b1;
  --color-darkslateblue: #102c57;
  --color-hotpink: #fa58b6;
  --color-darkslategray: #3c486b;
  --color-gray-100: #8c8c8c;
  --color-gray-200: rgba(255, 255, 255, 0.7);
  --color-whitesmoke: #edf0f4;
  --color-mediumblue: #0052ff;
  --color-mediumvioletred: #c40272;

  /* Gaps */
  --gap-base: 16px;

  /* Paddings */
  --padding-10xs-6: 2.6px;
  --padding-3xs: 10px;
  --padding-9xs: 4px;

  /* Border radiuses */
  --br-3xs-2: 9.2px;
  --br-5xs-9: 7.9px;
  --br-54xl-5: 73.5px;
  --br-45xl: 64px;
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-5xl: 24px;
  --br-7xs: 6px;
}
