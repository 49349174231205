.vectorParent {
  position: absolute;
  top: calc(50% + 1394px);
  left: calc(50% - 761px);
  background: linear-gradient(143.32deg, #041a3b, #10479b);
  width: 1520px;
  height: 385px;
  overflow: hidden;
  font-size: var(--font-size-sm);
  color: var(--color-lightgray);
  } 

  .frameChild {
    position: absolute;
    top: 289px;
    left: calc(50% - 820px);
    max-height: 100%;
    width: 1640px;
  }
  .complereChain {
    position: absolute;
    font-family: Inter;
    height: 5.06%;
    width: 26.39%;
    top: 80.06%;
    left: 5%;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .endUserLicence {
    width: 15.28%;
    left: 55.68%;
    justify-content: center;
    cursor: pointer;
    color:var(--color-whitesmoke);
  }
  .contactUs,
  .endUserLicence,
  .privacyPolicy {
    position: absolute;
    height: 5.06%;
    font-family: Inter;
    top: 80.06%;
    display: flex;
    align-items: center;
    color:var(--color-whitesmoke);
  }
  .privacyPolicy {
    width: 7.29%;
    left: 72.78%;
    text-align: left;
    cursor: pointer;
    color:var(--color-whitesmoke);
  }
  .contactUs {
    width: 6.11%;
    left: 80.89%;
    justify-content: center;
    cursor: pointer;
    color:var(--color-whitesmoke);
  }
  .groupIcon {
    height: 100%;
    width: 83.92%;
    top: 0;
    right: 16.08%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c,
  .groupIcon,
  .groupIcon1 {
    position: absolute;
  }
  .groupIcon1 {
    height: 100%;
    width: 83.92%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 16.08%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c {
    height: 58.89%;
    width: 37.06%;
    top: 20.56%;
    left: 31.47%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .complere,
  .groupContainer {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .groupContainer {
    width: 22.59%;
    right: 77.41%;
    bottom: 0;
    left: 0;
  }
  .complere {
    width: 74.25%;
    left: 25.75%;
    font-size: var(--font-size-11xl);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupParent,
  .groupWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .groupWrapper {
    height: 10.11%;
    width: 13.19%;
    top: 33.09%;
    right: 81.81%;
    bottom: 61.8%;
    left: 5%;
    font-size: var(--font-size-base-9);
    color: var(--color-white);
  }
  .facebookIcon {
    position: absolute;
    top: 178px;
    left: 1152px;
    width: 42px;
    height: 42px;
  }
  .newInstaIcon {
    position: absolute;
    top: 178px;
    left: 1088px;
    width: 42px;
    height: 42px;
  }
  .newFacebookIcon {
    position: absolute;
    top: 178px;
    left: 1030px;
    width: 42px;
    height: 42px;
  }
  .icons8Twitterx1 {
    width: 36.8px;
    position: relative;
    border-radius: 9.19px;
    height: 36.8px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .icons8Twitterx1Wrapper {
    position: absolute;
    top: 178px;
    left: 1210px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-10xs-6);
  }
  .aboutUs,
  .build,
  .ecosystem,
  .features,
  .learn {
    position: absolute;
    top: 0;
    left: 170px;
    font-weight: 500;
    font-family: Inter;
    cursor:pointer
  }
  .aboutUs,
  .build,
  .ecosystem,
  .features {
    top: 54px;
  }
  .aboutUs,
  .build,
  .features {
    top: 0;
    left: 0;
  }
  .build,
  .features {
    top: 54px;
  }
  .build {
    top: 0;
    left: 170px;
  }
  .learnParent {
    position: absolute;
    top: 140px;
    left: 390px;
    width: 354px;
    height: 78px;
    font-size: var(--font-size-xl);
    color: var(--color-white);
  }
  .wwwcomplerexyz {
    position: absolute;
    font-family: Inter;
    top: 50.82%;
    left: 5%;
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
  }
 
  .mob,
  .mobLogo{
    display: none;
  }

@media only screen and (max-device-width: 1640px){
  .vectorParent {
    position: absolute;
    top: calc(50% + 1394px);
    left: calc(50% - 761px);
    background: linear-gradient(143.32deg, #041a3b, #10479b);
    width: 1520px;
    height: 385px;
    overflow: hidden;
    font-size: var(--font-size-sm);
    color: var(--color-lightgray);
    } 

    .frameChild {
      position: absolute;
      top: 289px;
      left: calc(50% - 820px);
      max-height: 100%;
      width: 1640px;
    }
    .complereChain {
      position: absolute;
      height: 5.06%;
      width: 26.39%;
      top: 80.06%;
      left: 5%;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .endUserLicence {
      width: 15.28%;
      left: 55.68%;
      justify-content: center;
      cursor: pointer;
      color:var(--color-whitesmoke);
    }
    .contactUs,
    .endUserLicence,
    .privacyPolicy {
      position: absolute;
      height: 5.06%;
      top: 80.06%;
      display: flex;
      align-items: center;
      color:var(--color-whitesmoke);
    }
    .privacyPolicy {
      width: 7.29%;
      left: 72.78%;
      text-align: left;
      cursor: pointer;
      color:var(--color-whitesmoke);
    }
    .contactUs {
      width: 6.11%;
      left: 80.89%;
      justify-content: center;
      cursor: pointer;
      color:var(--color-whitesmoke);
    }
    .groupIcon {
      height: 100%;
      width: 83.92%;
      top: 0;
      right: 16.08%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c,
    .groupIcon,
    .groupIcon1 {
      position: absolute;
    }
    .groupIcon1 {
      height: 100%;
      width: 83.92%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 16.08%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c {
      height: 58.89%;
      width: 37.06%;
      top: 20.56%;
      left: 31.47%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .complere,
    .groupContainer {
      position: absolute;
      height: 100%;
      top: 0;
    }
    .groupContainer {
      width: 22.59%;
      right: 77.41%;
      bottom: 0;
      left: 0;
    }
    .complere {
      width: 74.25%;
      left: 25.75%;
      font-size: var(--font-size-11xl);
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .groupParent,
    .groupWrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .groupWrapper {
      height: 10.11%;
      width: 13.19%;
      top: 33.09%;
      right: 81.81%;
      bottom: 61.8%;
      left: 5%;
      font-size: var(--font-size-base-9);
      color: var(--color-white);
    }
    .facebookIcon {
      position: absolute;
      top: 178px;
      left: 1152px;
      width: 42px;
      height: 42px;
    }
    .newInstaIcon {
      position: absolute;
      top: 178px;
      left: 1088px;
      width: 42px;
      height: 42px;
    }
    .newFacebookIcon {
      position: absolute;
      top: 178px;
      left: 1030px;
      width: 42px;
      height: 42px;
    }
    .icons8Twitterx1 {
      width: 36.8px;
      position: relative;
      border-radius: 9.19px;
      height: 36.8px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .icons8Twitterx1Wrapper {
      position: absolute;
      top: 178px;
      left: 1210px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: var(--padding-10xs-6);
    }
    .aboutUs,
    .build,
    .ecosystem,
    .features,
    .learn {
      position: absolute;
      top: 0;
      left: 170px;
      font-weight: 500;
      cursor:pointer
    }
    .aboutUs,
    .build,
    .ecosystem,
    .features {
      top: 54px;
    }
    .aboutUs,
    .build,
    .features {
      top: 0;
      left: 0;
    }
    .build,
    .features {
      top: 54px;
    }
    .build {
      top: 0;
      left: 170px;
    }
    .learnParent {
      position: absolute;
      top: 140px;
      left: 390px;
      width: 354px;
      height: 78px;
      font-size: var(--font-size-xl);
      color: var(--color-white);
    }
    .wwwcomplerexyz {
      position: absolute;
      top: 50.82%;
      left: 5%;
      font-size: 16px;
      text-align: left;
      display: flex;
      align-items: center;
    }
   
    .mob,
    .mobLogo{
      display: none;
    }
    
}

@media only screen and (max-device-width: 1440px){
  .frameChild {
  position: absolute;
  top: 269px;
  left: calc(50% - 648px);
  max-height: 100%;
  width: 1296px;
}
.complereChain {
  position: absolute;
  height: 5.06%;
  width: 26.39%;
  top: 80.06%;
  left: 5%;
  text-align: left;
  display: flex;
  align-items: center;
}
.endUserLicence {
  width: 15.28%;
  left: 58.68%;
  justify-content: center;
  cursor: pointer;
  color:var(--color-whitesmoke);
}
.contactUs,
.endUserLicence,
.privacyPolicy {
  position: absolute;
  height: 5.06%;
  top: 80.06%;
  display: flex;
  align-items: center;
  color:var(--color-whitesmoke);
}
.privacyPolicy {
  width: 7.29%;
  left: 77.78%;
  text-align: left;
  cursor: pointer;
  color:var(--color-whitesmoke);
}
.contactUs {
  width: 6.11%;
  left: 88.89%;
  justify-content: center;
  cursor: pointer;
  color:var(--color-whitesmoke);
}
.groupIcon {
  height: 100%;
  width: 83.92%;
  top: 0;
  right: 16.08%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c,
.groupIcon,
.groupIcon1 {
  position: absolute;
}
.groupIcon1 {
  height: 100%;
  width: 83.92%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 16.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c {
  height: 58.89%;
  width: 37.06%;
  top: 20.56%;
  left: 31.47%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complere,
.groupContainer {
  position: absolute;
  height: 100%;
  top: 0;
}
.groupContainer {
  width: 22.59%;
  right: 77.41%;
  bottom: 0;
  left: 0;
}
.complere {
  width: 74.25%;
  left: 25.75%;
  font-size: var(--font-size-11xl);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupParent,
.groupWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.groupWrapper {
  height: 10.11%;
  width: 13.19%;
  top: 27.09%;
  right: 81.81%;
  bottom: 61.8%;
  left: 5%;
  font-size: var(--font-size-base-9);
  color: var(--color-white);
}
.facebookIcon {
  position: absolute;
  top: 178px;
  left: 1152px;
  width: 42px;
  height: 42px;
}
.newInstaIcon {
  position: absolute;
  top: 178px;
  left: 1088px;
  width: 42px;
  height: 42px;
}
.newFacebookIcon {
  position: absolute;
  top: 178px;
  left: 1030px;
  width: 42px;
  height: 42px;
}
.icons8Twitterx1 {
  width: 36.8px;
  position: relative;
  border-radius: 9.19px;
  height: 36.8px;
  overflow: hidden;
  flex-shrink: 0;
}
.icons8Twitterx1Wrapper {
  position: absolute;
  top: 178px;
  left: 1210px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs-6);
}
.aboutUs,
.build,
.ecosystem,
.features,
.learn {
  position: absolute;
  top: 0;
  left: 170px;
  font-weight: 500;
  cursor:pointer
}
.aboutUs,
.build,
.ecosystem,
.features {
  top: 54px;
}
.aboutUs,
.build,
.features {
  top: 0;
  left: 0;
}
.build,
.features {
  top: 54px;
}
.build {
  top: 0;
  left: 170px;
}
.learnParent {
  position: absolute;
  top: 100px;
  left: 390px;
  width: 354px;
  height: 78px;
  font-size: var(--font-size-xl);
  color: var(--color-white);
}
.wwwcomplerexyz {
  position: absolute;
  /* width: 11.39%; */
  top: 43.82%;
  left: 5%;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
}
.vectorParent {
  position: absolute;
  top: 3160px;
  left: calc(50% - 720px);
  background: linear-gradient(103.94deg, #041a3b, #10479b);
  width: 1440px;
  height: 356px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-lightgray);
  font-family: var(--font-inter);
}
.mob,
.mobLogo{
  display: none;
}
}



@media only screen and (max-device-width: 440px){
  .desktop{
    display: none;
  }
  .frameChild {
    position: absolute;
  top: 370px;
  left: calc(50% - 168px);
  max-height: 100%;
  width: 338px;
  background-color: white;
  }
  .complereChain {
    position: absolute;
    height: 20%;
    width: 100%;
    top: 300px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactUs,
.endUserLicence,
.privacyPolicy {
  position: absolute;
  height: 10%;
  width: 70.51%;
  top: 74.67%;
  left: 14.74%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUs,
.privacyPolicy {
  height: 10%;
  width: 47.73%;
  top: 84%;
  left: 0;
}
.contactUs {
  width: 40%;
  left: 60%;
}
  .vectorParent {
  position: absolute;
  top: calc(50% + 990px);
  left: calc(50% - 220px);
  background: linear-gradient(143.32deg, #041a3b, #10479b);
  width: 440px;
  height: 485px;
  overflow: hidden;
  font-size: var(--font-size-sm);
  color: var(--color-lightgray);
  }
  .groupParent,
.groupWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
 
}
  /* .groupWrapper {
    height: 10.11%;
    width: 13.19%;
    
    top: 88.09%;
    right: 81.81%;
    bottom: 61.8%;
    left: 5%;
    font-size: var(--font-size-base-9);
    color: var(--color-white);
  } */
  .wwwcomplerexyz{
    position: absolute;
    width: 100%;
    top: 15.26%;
    left: 0;
    font-size: var(--font-size-lg);
    color: var(--color-lightgray);
    display: flex;
    align-items: center;
    justify-content: center;
  }
.groupWrapper
  {
    position: absolute;
    top: 6px;
    left: calc(50% - 125px);
    width: 256px;
    height: 86px;
    font-size: var(--font-size-mini);
}
.learnParent{
  position: absolute;
  top: 136px;
  left: calc(50% - 130px);
  width: 100%;
  height: 86px;
  font-size: var(--font-size-mini);

  }

.ecosystem,
.learn {
  position: absolute;
  top: 0;
  left: 177px;
  font-weight: 500;
}
.ecosystem {
  top: 34px;
}
.aboutUs,
.build,
.documentation,
.features {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}
.build,
.documentation,
.features {
  top: 34px;
}
.build,
.documentation {
  top: 0px;
  left: 177px;
}
.documentation {
  left: 0;
}
.mob{
  position: absolute;
  top: 224px;
  left: 20px;
  width: 216px;
  height: 42px;
}
.mobLogo{
  display: flex;
  flex-direction: row;
  width:420px;
  height:40px;
  justify-content: center;
  position: absolute;
  top:260px;
  align-items: center;
  left:calc(50% - 200px);
  gap:20px;
}
.mobface{
  /* position: absolute;
  left:70px; */
  width: 35px;
  height: 35px;
}
}