.groupChild {
  position: absolute;
  height: 11.49%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 88.51%;
  left: 0;
  backdrop-filter: blur(24px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.1)
  );
}
.groupIcon {
  height: 100%;
  width: 84%;
  top: 0;
  right: 16%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c,
.groupIcon,
.groupIcon1 {
  position: absolute;
}
.groupIcon1 {
  height: 100%;
  width: 84%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 16%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.c {
  height: 58.81%;
  width: 37%;
  top: 20.48%;
  left: 31.6%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complere,
.groupContainer {
  position: absolute;
  height: 100%;
  top: 0;
}
.groupContainer {
  width: 22.57%;
  right: 77.43%;
  bottom: 0;
  left: 0;
}
.complere {
  width: 74.27%;
  left: 25.73%;
  font-size: var(--font-size-16xl);
  font-weight: 600;
  color: var(--color-darkslateblue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupParent,
.groupWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.groupWrapper {
  height: 6.03%;
  width: 15.38%;
  top: 2.73%;
  right: 79.62%;
  bottom: 91.24%;
  left: 5%;
}
.bridge {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  cursor: pointer;
}
.arrowUpRightFromSquareSvgIcon {
  position: absolute;
  top: 0;
  left: 71px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.bridgeParent {
  position: absolute;
  top: 0;
  left: 397px;
  width: 95px;
  height: 24px;
  color: var(--color-mediumvioletred);
  cursor: pointer;
}
.dropdownArrowSvgrepoCom1Icon {
  position: absolute;
  top: 0;
  left: 63px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.dropdownArrowSvgrepoCom1Parent,
.dropdownArrowSvgrepoCom2Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 87px;
  height: 24px;
  cursor: pointer;
}
.dropdownArrowSvgrepoCom2Icon {
  left: 56px;
  width: 24px;
  overflow: hidden;
}
.dropdownEco{
  position: absolute;
  top: 0;
  left: -150px;
  width: 107px;
  height: 24px;
  cursor: pointer;
}
.dropdownArrowSvgrepoCom2Parent,
.dropdownArrowSvgrepoCom3Icon {
  position: absolute;
  top: 0;
  left: 129px;
  width: 80px;
  height: 24px;
  cursor:pointer;
}
.dropdownArrowSvgrepoCom3Icon {
  left: 80px;
  width: 24px;
  overflow: hidden;
}
.dropdownArrowSvgrepoCom3Parent,
.groupDiv {
  position: absolute;
  top: 0;
  left: 251px;
  width: 104px;
  height: 24px;
}
.groupDiv {
  top: 28px;
  left: 876px;
  width: 492px;
  font-size: var(--font-size-xl);
  color: var(--color-darkslateblue);
}
.groupItem {
  position: absolute;
  top: 100px;
  left: 1049px;
  border-radius: var(--br-5xl);
  width: 389px;
  height: 77px;
}
.groupInner {
  top: 614px;
  left: 0;
  width: 1065px;
  height: 82px;
  object-fit: contain;
}
.groupChild1,
.groupInner,
.vectorIcon {
  position: absolute;
  border-radius: var(--br-5xl);
}
.vectorIcon {
  top: 381px;
  left: 540px;
  width: 270px;
  height: 184px;
}
.groupChild1 {
  top: 412px;
  left: 1232px;
  max-height: 100%;
  width: 207px;
}
.rectangleParent {
  position: absolute;
  height: 91.58%;
  width: 100%;
  top: 3.42%;
  right: 0;
  bottom: 5%;
  left: 0;
  text-align: center;
  font-size: var(--font-size-lg-5);
  color: var(--color-hotpink);
  font-family: var(--font-inter);
}
.ham,
.hamMenu,
.learnMenu {
display: none;
}

.learnMenu{
  width: 160px;
  z-index: 2;
  background-color: #092e66;
  /* background: transparent; */
  left:calc(50% - 85px);
  height:120px;
  top:98%;
  position:absolute;
  border: 1px;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  &:hover {
    /* box-shadow: 0 4px 1rem -4px #fa58b6; */
    transform: translateY(-3px);
  }
}

.learnItem{
  color:#ffff;
  /* margin-top: -10px; */
}
.learnItem2{
  color:#ffff;
  margin-top: -10px;
}

@media (max-width:1440px){
  .groupChild {
    position: absolute;
    height: 11.49%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 88.51%;
    left: 0;
    backdrop-filter: blur(24px);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    );
  }
  .groupIcon {
    height: 100%;
    width: 84%;
    top: 0;
    right: 16%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c,
  .groupIcon,
  .groupIcon1 {
    position: absolute;
  }
  .groupIcon1 {
    height: 100%;
    width: 84%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 16%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c {
    height: 58.81%;
    width: 37%;
    top: 20.48%;
    left: 31.6%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .complere,
  .groupContainer {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .groupContainer {
    width: 22.57%;
    right: 77.43%;
    bottom: 0;
    left: 0;
  }
  .complere {
    width: 74.27%;
    left: 25.73%;
    font-size: var(--font-size-16xl);
    font-weight: 600;
    color: var(--color-darkslateblue);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupParent,
  .groupWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .groupWrapper {
    height: 6.03%;
    width: 15.38%;
    top: 2.73%;
    right: 79.62%;
    bottom: 91.24%;
    left: 5%;
  }
  .bridge {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    cursor: pointer;
  }
  .arrowUpRightFromSquareSvgIcon {
    position: absolute;
    top: 0;
    left: 71px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .bridgeParent {
    position: absolute;
    top: 0;
    left: 397px;
    width: 95px;
    height: 24px;
    color: var(--color-mediumvioletred);
    cursor: pointer;
  }
  .dropdownArrowSvgrepoCom1Icon {
    position: absolute;
    top: 0;
    left: 63px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .dropdownArrowSvgrepoCom1Parent,
  .dropdownArrowSvgrepoCom2Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 87px;
    height: 24px;
    cursor: pointer;
  }
  .dropdownArrowSvgrepoCom2Icon {
    left: 56px;
    width: 24px;
    overflow: hidden;
  }
  .dropdownEco{
    position: absolute;
    top: 0;
    left: -150px;
    width: 107px;
    height: 24px;
    cursor: pointer;
  }
  .dropdownArrowSvgrepoCom2Parent,
  .dropdownArrowSvgrepoCom3Icon {
    position: absolute;
    top: 0;
    left: 129px;
    width: 80px;
    height: 24px;
    cursor:pointer;
  }
  .dropdownArrowSvgrepoCom3Icon {
    left: 80px;
    width: 24px;
    overflow: hidden;
  }
  .dropdownArrowSvgrepoCom3Parent,
  .groupDiv {
    position: absolute;
    top: 0;
    left: 251px;
    width: 104px;
    height: 24px;
  }
  .groupDiv {
    top: 28px;
    left: 876px;
    width: 492px;
    font-size: var(--font-size-xl);
    color: var(--color-darkslateblue);
  }
  .groupItem {
    position: absolute;
    top: 100px;
    left: 1049px;
    border-radius: var(--br-5xl);
    width: 389px;
    height: 77px;
  }
  .groupInner {
    top: 614px;
    left: 0;
    width: 1065px;
    height: 82px;
    object-fit: contain;
  }
  .groupChild1,
  .groupInner,
  .vectorIcon {
    position: absolute;
    border-radius: var(--br-5xl);
  }
  .vectorIcon {
    top: 381px;
    left: 540px;
    width: 270px;
    height: 184px;
  }
  .groupChild1 {
    top: 412px;
    left: 1232px;
    max-height: 100%;
    width: 207px;
  }
  .rectangleParent {
    position: absolute;
    height: 91.58%;
    width: 100%;
    top: 3.42%;
    right: 0;
    bottom: 5%;
    left: 0;
    text-align: center;
    font-size: var(--font-size-lg-5);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
  .ham,
  .hamMenu,
  .learnMenu {
  display: none;
  }
  
  .learnMenu{
    width: 160px;
    z-index: 2;
    background-color: #092e66;
    /* background: transparent; */
    left:calc(50% - 85px);
    height:120px;
    top:98%;
    position:absolute;
    border: 1px;
    border-width: 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  
    &:hover {
      /* box-shadow: 0 4px 1rem -4px #fa58b6; */
      transform: translateY(-3px);
    }
  }
  
  .learnItem{
    color:#ffff;
    /* margin-top: -10px; */
  }
  .learnItem2{
    color:#ffff;
    margin-top: -10px;
  }
  
}

@media only screen and (max-device-width: 440px){
  .groupItem,
  .groupInner,
  .vectorIcon,
  .groupChild1 {
    display: none;
  }
  .rectangleParent {
    position: absolute;
    height: 91.58%;
    width: 440px;
    top: 3.42%;
    right: 0;
    bottom: 5%;
    left: calc(50% - 220px);
    text-align: center;
    font-size: var(--font-size-lg-5);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
  .groupChild{
    position: absolute;
    top: -20px;
    left: calc(50% - 220px);
    box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
    background-color: var(--color-white);
    width: 440px;
    height: 54px;
    display: flex;
  justify-content: space-between;
  }
  .groupWrapper{
  position: absolute;
  height: 5.71%;
  width: 35.17%;
  top: -1.84%;
  right: 58.17%;
  bottom: 98.85%;
  left: 6.67%;
  font-size: var(--font-size-2xs-6);
  color: var(--color-hotpink);
  display: flex;
  justify-content: space-between;
  }
  .ham {
    display: block;
    }
  .hamburgerMenuSvgrepoCom1Icon {
    position: absolute;
    top: -18px;
    left: 356px;
    width: 45px;
    height: 45px;
    overflow: hidden;
  }
  .my-menu{
    width:50px;
    background-color: #a90000;
  }

  .hamMenu{
    width: 440px;
    z-index: 2;
    background-color: #092e66;
    /* background: transparent; */
    left:calc(50% - 220px);
    height:44%;
    top:6%;
    position:absolute;
    border: 1px;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  }
  .menItem{
    color:white;
    /* margin-top: -5px; */
  }
  .learnMenu{
    width: 440px;
    z-index: 2;
    background-color: #092e66;
    /* background: transparent; */
    left:calc(50% - 220px);
    height:22%;
    top:6%;
    position:absolute;
    border: 1px;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  }
  .learnItem{
    color:white;
  }
  .solid{
    border-top: 3px solid #bbb;
    width:100%;
  }
  .complere,
  .groupContainer,
  .groupParent {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .groupParent {
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .groupContainer {
    width: 22.59%;
    right: 77.41%;
    bottom: 0;
    left: 0;
  }
  .groupIcon {
    height: 100%;
    width: 83.92%;
    top: 0;
    right: 16.08%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c,
  .groupIcon,
  .groupIcon1 {
    position: absolute;
  }
  .groupIcon1 {
    height: 100%;
    width: 83.92%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 16.08%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c {
    height: 58.75%;
    width: 37.06%;
    top: 20.42%;
    left: 31.47%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .complere {
    width: 74.25%;
    left: 25.75%;
    font-size: var(--font-size-xl);
    font-weight: 600;
    color: var(--color-darkslateblue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@media only screen and (max-device-width: 362px){
  .hamburgerMenuSvgrepoCom1Icon {
    position: absolute;
    top: -18px;
    left: 350px;
    width: 45px;
    height: 45px;
    overflow: hidden;
  }
  .groupWrapper{
    position: absolute;
    height: 4.71%;
    width: 35.17%;
    top: -1.44%;
    right: 58.17%;
    bottom: 98.85%;
    left: 10.67%;
    font-size: var(--font-size-2xs-6);
    color: var(--color-hotpink);
    display: flex;
    justify-content: space-between;
    }
}