.revolutionizingWeb3With {
  position: absolute;
  width: 100%;
  top: 67.27%;
  left: 0;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complere {
  font-weight: 500;
}
.ecosystem {
  font-weight: 600;
  color: var(--color-hotpink);
}
.complereEcosystemTxtContainer {
  width: 100%;
}
.complereEcosystem {
  position: absolute;
  top: 0;
  left: calc(50% - 280px);
  font-size: var(--font-size-23xl);
  line-height: 56px;
  display: flex;
  align-items: center;
  width: 560px;
  color: var(--color-darkslateblue);
  font-family: var(--font-rubik);
}
.revolutionizingWeb3WithOurParent {
  position: absolute;
  height: 24.02%;
  width: 68.94%;
  top: 0;
  right: 15.53%;
  bottom: 75.98%;
  left: 15.53%;
}
.frameParent {
  position: absolute;
  top: 158px;
  left: calc(50% - 880px);
  /* left: 53%;
  transform: translate(-50%, 0); */
  /* background-color: aqua; */
  width: 1760px;
  height: 300px;
  font-size: var(--font-size-mid-3);
  color: var(--color-white);
}
.groupParent {
  position: absolute;
  height: 80.07%;
  width: 1580px;
  /* 1850px; */
  top: -2%;
  right: 5.28%;
  bottom: 9.97%;
  left: calc(50% - 790px);;
  /* calc(50% - 925px); */
}
.desktop1Inner {
  position: absolute;
  top: 1132px;
  left: calc(50% - 710px);
  background-color: var(--color-white);
  width: 1440px;
  height: 522px;
  overflow: hidden;
  text-align: center;
  font-size: 22px;
  color: var(--color-darkslategray);
  font-family: var(--font-inter);
}
.rfm-marquee-container{
  width:'!important'
}

.tokenImg:hover {
  background-color: brown;
  color: blue;
}

.cards_container {
  display: flex;
  /* border-radius: 6px; */
  /* color: #333; */
  /* background: #eee; */
  top: 158px;
  padding: 1rem;
  /* box-shadow: 0 0 1rem #000 inset; */
  overflow-x: auto;
  position: relative;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  flex: 2 0 200px;
  /* box-shadow: 0 1px 1rem -4px #000; */
  background: #fff;
  margin: 1rem;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  
  img {
    width: 200px;
    height: 250px;
    object-fit: center;
  }
  
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}

/* .card_img{
  width: 250px;
  height: 20px;
  object-fit: center;
} */

.card_content {
  padding: 0.1rem;
  background:#072452;
  margin-top: -15px;
  font-size: 12px;
  color:#fff;
}

.contain{
  width: 90%;
  margin: auto;
}

@media only screen and (max-device-width: 440px){
  .revolutionizingWeb3WithOurParent{
    position: absolute;
    top: 27px;
    left: calc(50% - 206px);
    width: 412px;
    height: 100px;
    font-size: var(--font-size-5xl);
    color: var(--color-darkslateblue);
    font-family: var(--font-rubik);
  }
  .revolutionizingWeb3With {
    position: absolute;
    width: 100%;
    top: 12%;
    left: 0;
    font-size: 20px;
    line-height: 24px;
    font-family: var(--font-inter);
    color: var(--color-darkslategray-100);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktop1Inner {
    position: absolute;
    top: 722px;
    left: calc(50% - 206px);
    background-color: var(--color-white);
    width: 412px;
    height: 572px;
    overflow: hidden;
    text-align: center;
    font-size: 22px;
    color: var(--color-darkslategray);
    font-family: var(--font-inter);
  }
  .groupParent {
    position: absolute;
    height: 80.07%;
    width: 1592px;
    /* 1440px; */
    top: 9.97%;
    right: 5.28%;
    bottom: 9.97%;
    left: calc(50% - 796px);;
    /* calc(50% - 710px); */
  }
  .complereEcosystemTxtContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .complereEcosystem{
    
      position: absolute;
      top: -50px;
      left: calc(50% - 210px);
      line-height: 48px;
      width: 420px;
  }
  .complere {
    font-weight: 500;
    font-size: 28px;
  }
  .ecosystem {
    font-weight: 600;
    color: var(--color-hotpink);
    font-size: 28px;
    margin-left: 5px;
  }
  .frameParent {
    position: absolute;
    top: 158px;
    left: calc(50% - 275px);
    /* left: 53%;
    transform: translate(-50%, 0); */
    /* background-color: aqua; */
    /* width: 1000px; */
    height: 300px;
    font-size: var(--font-size-mid-3);
    color: var(--color-white);
  }
  .cards_container {
    display: flex;
    /* border-radius: 6px; */
    /* color: #333; */
    /* background: #eee; */
    top: 158px;
    padding: 1rem;
    /* box-shadow: 0 0 1rem #000 inset; */
    overflow-x: auto;
    position: relative;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .card {
    flex: 2 0 200px;
    /* box-shadow: 0 1px 1rem -4px #000; */
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    
    img {
      width: 200px;
      height: 250px;
      object-fit: center;
    }
    
    &:hover {
      box-shadow: 0 4px 1rem -4px #000;
      transform: translateY(-3px);
    }
  }
  
  /* .card_img{
    width: 250px;
    height: 20px;
    object-fit: center;
  } */
  
  .card_content {
    padding: 0.1rem;
    background:#072452;
    margin-top: -15px;
    font-size: 12px;
    color:#fff;
  }
  
  .contain{
    width: 26%;
    margin: auto;
  }
}
@media only screen and (max-device-width: 362px){
  .revolutionizingWeb3With {
    position: absolute;
    width: 360px;
    top: 12%;
    left: calc(50% - 180px);
    font-size: 20px;
    line-height: 24px;
    font-family: var(--font-inter);
    color: var(--color-darkslategray-100);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contain{
    width: 22%;
    margin: auto;
  }
  .cards_container {
    display: flex;
    /* border-radius: 6px; */
    /* color: #333; */
    /* background: #eee; */
    top: 156px;
    padding: 1rem;
    /* box-shadow: 0 0 1rem #000 inset; */
    overflow-x: auto;
    position: relative;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
}