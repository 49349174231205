.groupChild {
    position: absolute;
    height: 11.49%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 88.51%;
    left: 0;
    backdrop-filter: blur(24px);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    );
  }
  .groupIcon {
    height: 100%;
    width: 84%;
    top: 0;
    right: 16%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c,
  .groupIcon,
  .groupIcon1 {
    position: absolute;
  }
  .groupIcon1 {
    height: 100%;
    width: 84%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 16%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .c {
    height: 58.81%;
    width: 37%;
    top: 20.48%;
    left: 31.6%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .complere,
  .groupContainer {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .groupContainer {
    width: 22.57%;
    right: 77.43%;
    bottom: 0;
    left: 0;
  }
  .complere {
    width: 74.27%;
    left: 25.73%;
    font-size: var(--font-size-16xl);
    font-weight: 600;
    color: var(--color-darkslateblue);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .groupParent,
  .groupWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .groupWrapper {
    height: 6.03%;
    width: 15.38%;
    top: 0%;
    right: 79.62%;
    bottom: 91.24%;
    left: 5%;
  }
  .bridge {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
  }
  .arrowUpRightFromSquareSvgIcon {
    position: absolute;
    top: 0;
    left: 71px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .bridgeParent {
    position: absolute;
    top: 0;
    left: 397px;
    width: 95px;
    height: 24px;
    color: var(--color-mediumvioletred);
  }
  .dropdownArrowSvgrepoCom1Icon {
    position: absolute;
    top: 0;
    left: 63px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .dropdownArrowSvgrepoCom1Parent,
  .dropdownArrowSvgrepoCom2Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 87px;
    height: 24px;
  }
  .dropdownArrowSvgrepoCom2Icon {
    left: 56px;
    width: 24px;
    overflow: hidden;
  }
  .dropdownArrowSvgrepoCom2Parent,
  .dropdownArrowSvgrepoCom3Icon {
    position: absolute;
    top: 0;
    left: 129px;
    width: 80px;
    height: 24px;
  }
  .dropdownArrowSvgrepoCom3Parent4{
    position: absolute;
    top: 0;
    left: -145px;
    width: 104px;
    height: 24px;
  }
  .dropdownArrowSvgrepoCom3Icon {
    left: 80px;
    width: 24px;
    overflow: hidden;
  }
  .dropdownArrowSvgrepoCom3Parent,
  .groupDiv {
    position: absolute;
    top: 0;
    left: 251px;
    width: 104px;
    height: 24px;
  }
  .groupDiv {
    /* top: 28px; */
    left: 876px;
    width: 492px;
    font-size: var(--font-size-xl);
    color: var(--color-darkslateblue);
  }
  .groupItem {
    position: absolute;
    top: 100px;
    left: 1049px;
    border-radius: var(--br-5xl);
    width: 389px;
    height: 77px;
  }
  .groupInner {
    top: 614px;
    left: 0;
    width: 1065px;
    height: 82px;
    object-fit: contain;
  }
  .groupChild1,
  .groupInner,
  .vectorIcon {
    position: absolute;
    border-radius: var(--br-5xl);
  }
  .vectorIcon {
    top: 381px;
    left: 540px;
    width: 270px;
    height: 184px;
  }
  .groupChild1 {
    top: 412px;
    left: 1232px;
    max-height: 100%;
    width: 207px;
  }
  .rectangleParent {
    position: absolute;
    height: 91.58%;
    width: 100%;
    top: 3.42%;
    right: 0;
    bottom: 5%;
    left: 0;
    text-align: center;
    font-size: var(--font-size-lg-5);
    color: var(--color-hotpink);
    font-family: var(--font-inter);
  }
  /* .desktop1 {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    height: 3515px;
    overflow: hidden;
  } */
  /* .groupParent {
    top: 0;
    left: calc(50% - 720px);
    background-color: var(--color-white);
    width: 1440px;
    height: 760px;
  } */
  .header{
    position:absolute;
    top:20%;
    width:100%;
    display: flex;
    justify-content: center;
    font-family: var(--font-inter);
    color: var(--color-darkslateblue);
  }

  .faq{
    position:absolute;
    top:40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: skyblue; */
    width:96%;
    gap:380px;
    padding-left: 60px;
  }
  .faqContent{
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    height:260px;
  }

.accordion {
    /* position:absolute;
    top:40%; */
    width:45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .accordion2{
    /* position:absolute;
    top:20%; */
    width:45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .accordionHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: var(--font-inter);
    color: var(--color-darkslateblue);
    font-weight: 500;
  }
  .accordionHeader2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: var(--font-inter);
    color: var(--color-darkslateblue);
    font-weight: 500;
  }

  .accordionTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color:rgba(242, 242, 242, 1);
    /* height:30px; */
    border-radius: 5px;
    padding: 6px;
  }
  .accordionTitle2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color:rgba(242, 242, 242, 1);
    /* height:30px; */
    border-radius: 5px;
    padding: 6px;
  }
  

  .accordionContent{
    font-family: var(--font-inter);
    margin-top: 5px;
  }
  .accordionContent2{
    font-family: var(--font-inter);
    margin-top: 5px;
  }
  .accordion-title:hover {
    background-color: #3ab4cc;
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background-color: #39b9d2;
  }

  .contactBox{
    /* position:absolute; */
    /* top:150%; */
    margin-top: 80px;
    background-color:rgba(242, 242, 242, 1);
    width:95%;
    border-radius: 5px;
    height: 220px;
    /* padding-left:10px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .boxa{
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    font-family: 'inter';
  }

  .conbox{
    background: linear-gradient(to right, rgba(249, 112, 191, 1),rgba(196, 2, 114, 1));
    width:40%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
    color:white;
    cursor: pointer;
  }

  .conIcon{
    height: 100%;
    width: 84%;
    top: 0;
    right: 16%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .policyText{
    position:absolute;
    top:20%;
    left:calc(50% - 615px);
    line-height: 42px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    width:80%
  }
  .policyText2{
    position:absolute;
    top:52%;
    left:calc(50% - 615px);
    line-height: 38px;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    width:80%;
    /* margin-bottom: -100px; */
  }
  .bold{
    padding-top: 18px;
  }
  .bold1{
    padding-top: 18px;
  }
  
  .footer{
    position:absolute;
    top:-20%;
    width:100%;
    /* left:calc(50% - 220px); */
  }
  
  .learnMenu{
    width: 160px;
    z-index: 2;
    background-color: #092e66;
    /* background: transparent; */
    left:calc(50% - 85px);
    height:120px;
    top:98%;
    position:absolute;
    border: 1px;
    border-width: 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  
    &:hover {
      /* box-shadow: 0 4px 1rem -4px #fa58b6; */
      transform: translateY(-3px);
    }
  }
  
  .learnItem{
    color:#ffff;
    /* margin-top: -10px; */
  }
  .learnItem2{
    color:#ffff;
    margin-top: -10px;
  }
  .ham,
  .hamMenu {
  display: none;
  }

  @media screen and (max-width: 1440px){
      .groupChild {
      position: absolute;
      height: 11.49%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 88.51%;
      left: 0;
      backdrop-filter: blur(24px);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.1)
      );
    }
    .groupIcon {
      height: 100%;
      width: 84%;
      top: 0;
      right: 16%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c,
    .groupIcon,
    .groupIcon1 {
      position: absolute;
    }
    .groupIcon1 {
      height: 100%;
      width: 84%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 16%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      object-fit: contain;
    }
    .c {
      height: 58.81%;
      width: 37%;
      top: 20.48%;
      left: 31.6%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .complere,
    .groupContainer {
      position: absolute;
      height: 100%;
      top: 0;
    }
    .groupContainer {
      width: 22.57%;
      right: 77.43%;
      bottom: 0;
      left: 0;
    }
    .complere {
      width: 74.27%;
      left: 25.73%;
      font-size: var(--font-size-16xl);
      font-weight: 600;
      color: var(--color-darkslateblue);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .groupParent,
    .groupWrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .groupWrapper {
      height: 6.03%;
      width: 15.38%;
      top: 0%;
      right: 79.62%;
      bottom: 91.24%;
      left: 5%;
    }
    .bridge {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 500;
    }
    .arrowUpRightFromSquareSvgIcon {
      position: absolute;
      top: 0;
      left: 71px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .bridgeParent {
      position: absolute;
      top: 0;
      left: 397px;
      width: 95px;
      height: 24px;
      color: var(--color-mediumvioletred);
    }
    .dropdownArrowSvgrepoCom1Icon {
      position: absolute;
      top: 0;
      left: 63px;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom1Parent,
    .dropdownArrowSvgrepoCom2Icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 87px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom2Icon {
      left: 56px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom2Parent,
    .dropdownArrowSvgrepoCom3Icon {
      position: absolute;
      top: 0;
      left: 129px;
      width: 80px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Parent4{
      position: absolute;
      top: 0;
      left: -145px;
      width: 104px;
      height: 24px;
    }
    .dropdownArrowSvgrepoCom3Icon {
      left: 80px;
      width: 24px;
      overflow: hidden;
    }
    .dropdownArrowSvgrepoCom3Parent,
    .groupDiv {
      position: absolute;
      top: 0;
      left: 251px;
      width: 104px;
      height: 24px;
    }
    .groupDiv {
      /* top: 28px; */
      left: 876px;
      width: 492px;
      font-size: var(--font-size-xl);
      color: var(--color-darkslateblue);
    }
    .groupItem {
      position: absolute;
      top: 100px;
      left: 1049px;
      border-radius: var(--br-5xl);
      width: 389px;
      height: 77px;
    }
    .groupInner {
      top: 614px;
      left: 0;
      width: 1065px;
      height: 82px;
      object-fit: contain;
    }
    .groupChild1,
    .groupInner,
    .vectorIcon {
      position: absolute;
      border-radius: var(--br-5xl);
    }
    .vectorIcon {
      top: 381px;
      left: 540px;
      width: 270px;
      height: 184px;
    }
    .groupChild1 {
      top: 412px;
      left: 1232px;
      max-height: 100%;
      width: 207px;
    }
    .rectangleParent {
      position: absolute;
      height: 91.58%;
      width: 100%;
      top: 3.42%;
      right: 0;
      bottom: 5%;
      left: 0;
      text-align: center;
      font-size: var(--font-size-lg-5);
      color: var(--color-hotpink);
      font-family: var(--font-inter);
    }
    /* .desktop1 {
      width: 100%;
      position: relative;
      background-color: var(--color-white);
      height: 3515px;
      overflow: hidden;
    } */
    /* .groupParent {
      top: 0;
      left: calc(50% - 720px);
      background-color: var(--color-white);
      width: 1440px;
      height: 760px;
    } */
    .header{
        position:absolute;
        top:20%;
        display: flex;
        justify-content: center;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
      }

      .faq{
        position:absolute;
        top:40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: skyblue; */
        width:96%;
        gap:380px;
        padding-left: 60px;
      }
      .faqContent{
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        height:260px;
      }
    
    .accordion {
        /* position:absolute;
        top:40%; */
        width:45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
      .accordion2{
        /* position:absolute;
        top:20%; */
        width:45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
    
      .accordionHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
      .accordionHeader2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
    
      .accordionTitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      .accordionTitle2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      
    
      .accordionContent{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordionContent2{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordion-title:hover {
        background-color: #3ab4cc;
      }
      
      .accordion-title,
      .accordion-content {
        padding: 1rem;
      }
      
      .accordion-content {
        background-color: #39b9d2;
      }
    
      .contactBox{
        /* position:absolute; */
        /* top:150%; */
        margin-top: 80px;
        background-color:rgba(242, 242, 242, 1);
        width:95%;
        border-radius: 5px;
        height: 220px;
        /* padding-left:10px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    
      .boxa{
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        font-family: 'inter';
      }
    
      .conbox{
        background: linear-gradient(to right, rgba(249, 112, 191, 1),rgba(196, 2, 114, 1));
        width:40%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;
        color:white;
        cursor: pointer;
      }
    
      .conIcon{
        height: 100%;
        width: 84%;
        top: 0;
        right: 16%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }

    .policyText{
      position:absolute;
      top:20%;
      left:calc(50% - 615px);
      line-height: 42px;
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      letter-spacing: 0px;
      width:80%
    }
    .policyText2{
      position:absolute;
      top:50%;
      left:calc(50% - 615px);
      line-height: 38px;
      font-size: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      letter-spacing: 0px;
      width:80%;
      /* margin-bottom: -100px; */
    }
    .bold{
      padding-top: 18px;
    }
    .bold1{
      padding-top: 18px;
    }
    
    .footer{
      position:absolute;
      top:-215%;
      width:100%;
      /* left:calc(50% - 220px); */
    }
  
    .learnMenu{
      width: 160px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 85px);
      height:120px;
      top:98%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    
      &:hover {
        /* box-shadow: 0 4px 1rem -4px #fa58b6; */
        transform: translateY(-3px);
      }
    }
    
    .learnItem{
      color:#ffff;
      /* margin-top: -10px; */
    }
    .learnItem2{
      color:#ffff;
      margin-top: -10px;
    }
    .ham,
    .hamMenu,
    .learnMenu {
    display: none;
    }
  }
  
    @media (max-width: 440px) and (max-height:900px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .header{
        display: flex;
        justify-content: center;
        width:100%;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-size: 13px;
      }

      .faq{
        position:absolute;
        top:40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: skyblue; */
        width:98%;
        gap:380px;
        padding-left: 20px;
      }

      .faqContent{
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height:260px;
        gap:20px;
      }
      .accordion {
        /* position:absolute;
        top:40%; */
        width:95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
      .accordion2{
        /* position:absolute;
        top:20%; */
        width:95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
      .accordionHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
      .accordionHeader2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
      .accordionTitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      .accordionTitle2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      .accordionContent{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordionContent2{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordion-title:hover {
        background-color: #3ab4cc;
      }
      
      .accordion-title,
      .accordion-content {
        padding: 1rem;
      }
      
      .accordion-content {
        background-color: #39b9d2;
      }
      .contactBox{
        /* position:absolute; */
        /* top:150%; */
        margin-top: 780px;
        background-color:rgba(242, 242, 242, 1);
        width:96%;
        border-radius: 5px;
        height: 220px;
        /* padding-left:10px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .boxa{
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        font-family: 'inter';
        font-size: 12px;
      }
    
      .conbox{
        background: linear-gradient(to right, rgba(249, 112, 191, 1),rgba(196, 2, 114, 1));
        width:50%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;
        color:white;
        cursor: pointer;
      }

      .conIcon{
        height: 100%;
        width: 84%;
        top: 0;
        right: 16%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }

      .policyText{
        position:absolute;
        top:10%;
        left:calc(50% - 170px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100%;
      }
      .policyText2{
        position:absolute;
        top:65%;
        left:calc(50% - 170px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100vh;
        /* margin-bottom: -100px; */
      }
      .bold{
        padding-top: 18px;
      }
      .bold1{
        padding-top: 8px;
      }
      .footer{
        position:absolute;
        top:120%;
        width:110%;
        height:60px;
        left:calc(50% - 220px);
      }
  
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 8.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .groupChild{
        position: absolute;
        top: -62px;
        left: calc(50% - 220px);
        box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
        background-color: var(--color-white);
        width: 440px;
        height: 54px;
      }
      .groupWrapper{
        position: absolute;
      height: 0.71%;
      width: 35.17%;
      top: -7.44%;
      right: 58.17%;
      bottom: 98.85%;
      left: 6.67%;
      font-size: var(--font-size-2xs-6);
      color: var(--color-hotpink);
      }
      .ham {
        display: block;
        }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: -58px;
        left: 370px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      
    .hamMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:44%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .menItem{
      color:white;
      /* margin-top: -5px; */
    }
    .learnMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:22%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .learnItem{
      color:white;
    }
      .complere,
      .groupContainer,
      .groupParent {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupParent {
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .groupContainer {
        width: 22.59%;
        right: 77.41%;
        bottom: 0;
        left: 0;
      }
      .groupIcon {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 16.08%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16.08%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.75%;
        width: 37.06%;
        top: 20.42%;
        left: 31.47%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere {
        width: 74.25%;
        left: 25.75%;
        font-size: var(--font-size-xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    @media (max-width: 440px) and (max-height:700px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .policyText{
        position:absolute;
        top:10%;
        left:calc(50% - 170px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100%;
      }
      .policyText2{
        position:absolute;
        top:75%;
        left:calc(50% - 170px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100vh;
        /* margin-bottom: -100px; */
      }
      .bold{
        padding-top: 18px;
      }
      .bold1{
        padding-top: 8px;
      }
      .footer{
        position:absolute;
        top:140%;
        width:110%;
        height:60px;
        left:calc(50% - 220px);
      }
  
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 8.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .groupChild{
        position: absolute;
        top: -62px;
        left: calc(50% - 220px);
        box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
        background-color: var(--color-white);
        width: 440px;
        height: 54px;
      }
      .groupWrapper{
        position: absolute;
      height: 0.71%;
      width: 35.17%;
      top: -8.44%;
      right: 58.17%;
      bottom: 98.85%;
      left: 6.67%;
      font-size: var(--font-size-2xs-6);
      color: var(--color-hotpink);
      }
      .ham {
        display: block;
        }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: -54px;
        left: 370px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      
    .hamMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:44%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .menItem{
      color:white;
      /* margin-top: -5px; */
    }
    .learnMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:22%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .learnItem{
      color:white;
    }
      .complere,
      .groupContainer,
      .groupParent {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupParent {
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .groupContainer {
        width: 22.59%;
        right: 77.41%;
        bottom: 0;
        left: 0;
      }
      .groupIcon {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 16.08%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16.08%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.75%;
        width: 37.06%;
        top: 20.42%;
        left: 31.47%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere {
        width: 74.25%;
        left: 25.75%;
        font-size: var(--font-size-xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    @media (max-width: 370px) and (max-height:700px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .header{
        display: flex;
        justify-content: center;
        width:100%;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-size: 12px;
      }
      .faq{
        position:absolute;
        top:40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: skyblue; */
        width:100%;
        gap:380px;
        padding-left: 20px;
      }
      .faqContent{
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height:260px;
        gap:20px;
      }
      .accordion {
        /* position:absolute;
        top:40%; */
        width:99%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
      .accordion2{
        /* position:absolute;
        top:20%; */
        width:99%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
      }
      .accordionHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
      .accordionHeader2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--font-inter);
        color: var(--color-darkslateblue);
        font-weight: 500;
      }
      .accordionTitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      .accordionTitle2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background-color:rgba(242, 242, 242, 1);
        /* height:30px; */
        border-radius: 5px;
        padding: 6px;
      }
      .accordionContent{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordionContent2{
        font-family: var(--font-inter);
        margin-top: 5px;
      }
      .accordion-title:hover {
        background-color: #3ab4cc;
      }
      .accordion-title,
      .accordion-content {
        padding: 1rem;
      }
      
      .accordion-content {
        background-color: #39b9d2;
      }
      .contactBox{
        /* position:absolute; */
        /* top:150%; */
        margin-top: 800px;
        background-color:rgba(242, 242, 242, 1);
        width:100%;
        border-radius: 5px;
        height: 220px;
        /* padding-left:10px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .policyText{
        position:absolute;
        top:10%;
        left:calc(50% - 140px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100%;
      }
      .policyText2{
        position:absolute;
        top:85%;
        left:calc(50% - 140px);
        line-height: 28px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        width:80%;
        height:100vh;
        /* margin-bottom: -100px; */
      }
      .bold{
        padding-top: 18px;
      }
      .bold1{
        padding-top: 8px;
      }
      .footer{
        position:absolute;
        top:138%;
        width:110%;
        height:60px;
        left:calc(50% - 190px);
      }
  
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 360px;
        top: 8.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 180px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .groupChild{
        position: absolute;
        top: -62px;
        left: calc(50% - 180px);
        box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
        background-color: var(--color-white);
        width: 360px;
        height: 54px;
      }
      .groupWrapper{
        position: absolute;
      height: 0.71%;
      width: 35.17%;
      top: -8.44%;
      right: 58.17%;
      bottom: 98.85%;
      left: 1.67%;
      font-size: var(--font-size-2xs-6);
      color: var(--color-hotpink);
      }
      .ham {
        display: block;
        }
      .hamburgerMenuSvgrepoCom1Icon {
        position: absolute;
        top: -54px;
        left: 320px;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }
      
    .hamMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:44%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .menItem{
      color:white;
      /* margin-top: -5px; */
    }
    .learnMenu{
      width: 440px;
      z-index: 2;
      background-color: #092e66;
      /* background: transparent; */
      left:calc(50% - 220px);
      height:22%;
      top:0%;
      position:absolute;
      border: 1px;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }
    .learnItem{
      color:white;
    }
      .complere,
      .groupContainer,
      .groupParent {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupParent {
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .groupContainer {
        width: 22.59%;
        right: 77.41%;
        bottom: 0;
        left: 0;
      }
      .groupIcon {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 16.08%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16.08%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.75%;
        width: 37.06%;
        top: 20.42%;
        left: 31.47%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere {
        width: 74.25%;
        left: 25.75%;
        font-size: var(--font-size-xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
   
    /* @media screen and (max-width: 440px) and (max-height:860px){
      .groupItem,
      .groupInner,
      .vectorIcon,
      .groupDiv,
      .groupChild1 {
        display: none;
      }
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 3.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .footer{
        position:absolute;
        top:320%;
        width:100%;
        height:60px;
        left:calc(50% - 200px);
      }
  
      .rectangleParent {
        position: absolute;
        height: 91.58%;
        width: 440px;
        top: 8.42%;
        right: 0;
        bottom: 5%;
        left: calc(50% - 220px);
        text-align: center;
        font-size: var(--font-size-lg-5);
        color: var(--color-hotpink);
        font-family: var(--font-inter);
      }
      .groupChild{
        position: absolute;
        top: -62px;
        left: calc(50% - 220px);
        box-shadow: 0 4px 12px 1px rgba(210, 210, 210, 0.5);
        background-color: var(--color-white);
        width: 440px;
        height: 54px;
      }
      .groupWrapper{
        position: absolute;
      height: 0.71%;
      width: 35.17%;
      top: -7.44%;
      right: 58.17%;
      bottom: 98.85%;
      left: 6.67%;
      font-size: var(--font-size-2xs-6);
      color: var(--color-hotpink);
      }
      .complere,
      .groupContainer,
      .groupParent {
        position: absolute;
        height: 100%;
        top: 0;
      }
      .groupParent {
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .groupContainer {
        width: 22.59%;
        right: 77.41%;
        bottom: 0;
        left: 0;
      }
      .groupIcon {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 16.08%;
        bottom: 0;
        left: 0;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c,
      .groupIcon,
      .groupIcon1 {
        position: absolute;
      }
      .groupIcon1 {
        height: 100%;
        width: 83.92%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 16.08%;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
        object-fit: contain;
      }
      .c {
        height: 58.75%;
        width: 37.06%;
        top: 20.42%;
        left: 31.47%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .complere {
        width: 74.25%;
        left: 25.75%;
        font-size: var(--font-size-xl);
        font-weight: 600;
        color: var(--color-darkslateblue);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    } */