.litecoinLtcIcon {
  position: absolute;
  height: 10.67%;
  width: 4.44%;
  top: 76.33%;
  right: 11.11%;
  bottom: 13%;
  left: 84.44%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

@media only screen and (max-device-width: 440px){
  .litecoinLtcIcon {
   display: none;
  }
}