/* .groupChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 96px;
  height: 96px;
} */
/* .div,
.institutionalPartnerships {
  position: absolute;
  top: 0;
  display: flex;
} */
/* .div {
  left: 0;
  line-height: 96px;
  font-weight: 600;
  align-items: flex-end;
  justify-content: center;
  width: 44px;
} */
/* .institutionalPartnerships {
  height: 100%;
  width: 73.02%;
  left: 26.98%;
  font-size: var(--font-size-9xl);
  line-height: 36px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-whitesmoke);
  text-align: left;
  align-items: center;
} */
/* .groupContainer,
.parent {
  position: absolute;
  top: 0;
  left: 126px;
  width: 252px;
  height: 96px;
}
.groupContainer {
  top: 52px;
  left: 812px;
  width: 378px;
}
.tokenized {
  margin: 0;
}
.tokenizedCommoditiesTxt {
  line-break: anywhere;
  width: 100%;
}
.tokenizedCommodities {
  position: absolute;
  height: 100%;
  width: 42.59%;
  top: 0;
  left: 57.41%;
  line-height: 36px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.m,
.mWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 224px;
}
.m {
  line-height: 96px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.mWrapper {
  height: 96px;
  text-align: center;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.groupDiv,
.tokenizedCommoditiesParent {
  position: absolute;
  height: 100%;
  width: 77.42%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 22.58%;
}
.groupDiv {
  height: 48%;
  width: 43.06%;
  top: 26%;
  right: 48.77%;
  bottom: 26%;
  left: 8.18%;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-whitesmoke);
  font-family: var(--font-inter);
}
.groupParent {
  position: absolute;
  top: 796px;
  left: calc(50% - 648px);
  border-radius: var(--br-5xl);
  background: linear-gradient(103.94deg, #041a3b, #10479b);
  width: 1296px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
} */

.desktopVersionInner {
  position: absolute;
  top: 848px;
  left: calc(50% - 648px);
  border-radius: 24px;
  background: linear-gradient(103.94deg, #041a3b, #10479b);
  width: 1296px;
  height: 172px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-23xl);
  font-family: var(--font-rubik);
}
.groupParent2 {
  position: absolute;
  top: 34px;
  left: 57px;
  width: 1182px;
  height: 104px;
}
.groupParent3 {
  position: absolute;
  top: 0;
  left: 639px;
  width: 201px;
  height: 104px;
}
.groupParent4 {
  position: absolute;
  top: 40px;
  left: 0;
  width: 201px;
  height: 64px;
}
.groupItem,
.m,
.rectangleParent {
  position: absolute;
}
.groupItem {
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
}
.m {
  top: 2px;
  left: 84px;
  line-height: 60px;
  font-weight: 600;
  color:var(--color-white);
}
.onChainLoans {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: rgba(237, 240, 244, 0.8);;
}
.groupParent5 {
  position: absolute;
  top: 0;
  left: 936px;
  width: 246px;
  height: 104px;
}
.groupInner,
.groupParent3 {
  position: absolute;
  top: 0;
  left: 639px;
  width: 201px;
  height: 104px;
}
.groupInner {
  top: 40px;
  left: 0;
  width: 64px;
  height: 64px;
}
.div {
  position: absolute;
  top: 42px;
  left: 84px;
  line-height: 60px;
  font-weight: 600;
  color:var(--color-white)
}
.institutionalPartnerships {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: rgba(237, 240, 244, 0.8);;
  display: flex;
  align-items: center;
  width: 246px;
}
.groupChild1,
.groupChild2,
.vectorIcon {
  position: absolute;
  top: 11px;
  left: 274px;
  max-width: 100%;
  overflow: hidden;
  height: 82px;
  /* color:#fff; */
}
.groupChild1,
.groupChild2 {
  left: 591px;
}
.groupChild2 {
  left: 888px;
}
.tokenizedAssets {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 180px;
}
.mParent,
.tokenizedAssetsParent {
  position: absolute;
  top: 40px;
  left: 0;
  width: 226px;
  height: 64px;
  font-size: var(--font-size-23xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.tokenizedAssetsParent {
  top: 0;
  height: 104px;
  font-size: var(--font-size-xl);
  color: rgba(237, 240, 244, 0.8);
  font-family: var(--font-inter);
}
.creditLineSecured {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
  font-weight: 500;
  color: rgba(237, 240, 244, 0.8);
  display: flex;
  align-items: center;
  width: 190px;
}
.creditLineSecuredParent,
.mGroup {
  position: absolute;
  top: 40px;
  left: 0;
  width: 221px;
  height: 64px;
  font-size: var(--font-size-23xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.creditLineSecuredParent {
  top: 0;
  left: 322px;
  height: 104px;
  font-size: var(--font-size-xl);
  color: var(--color-whitesmoke-200);
  font-family: var(--font-inter);
}

@media only screen and (max-device-width: 440px){
  .desktopVersionInner{
    position: absolute;
    top: 550px;
    left: calc(50% - 191px);
    border-radius: var(--br-xs);
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    width: 382px;
    height: 170px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--color-whitesmoke-200);
  }
  .groupParent2{
  position: absolute;
  top: 21px;
  left: 23px;
  width: 266px;
  height: 128px;
  }
  .groupInner{
    position: absolute;
    top: 20px;
    left: -25px;
    width: 103px;
    height: 32px;
  }

  .tokenizedAssetsParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 103px;
  height: 32px;
}
.tokenizedAssets {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 155px;
  font-size: 14px;
}
.div {
  position: absolute;
  top: 21px;
  left: 62px;
  line-height: 30px;
  font-weight: 600;
}
.groupParent5 {
  position: absolute;
  top: 0;
  left: 163px;
  width: 123px;
  height: 52px;
  font-size: var(--font-size-2xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.institutionalPartnerships {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-whitesmoke-200);
  display: flex;
  align-items: center;
  /* width: 155px; */
  color:rgba(237, 240, 244, 0.8);
}

.creditLineSecuredParent,
.groupParent4 {
  position: absolute;
  top: 76px;
  left: 0;
  width: 111px;
  height: 52px;
}
.creditLineSecured {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 155px;
  color:rgba(237, 240, 244, 0.8)
}
.mGroup {
  position: absolute;
  top: 20px;
  left: 0;
  width: 111px;
  height: 32px;
  font-size: var(--font-size-2xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.m {
  position: absolute;
  top: 1px;
  left: 62px;
  line-height: 30px;
  font-weight: 600;
}
.mParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
}
.mParent {
  top: 20px;
  width: 113px;
  font-size: var(--font-size-2xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.groupChild2,
.groupChild1,
.vectorIcon{
  display: none;
}

.groupParent3{
  position: absolute;
  top: 76px;
  left: 163px;
  width: 101px;
  height: 52px;
  font-size: var(--font-size-2xl);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.groupParent4 {
  top: 20px;
  width: 101px;
  height: 32px;
}

.groupItem {
  position: absolute;
  height: 99.06%;
  width: 13.62%;
  top: 0;
  right: 86.38%;
  bottom: 0.94%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupItem {
  height: 99.88%;
  width: 60.98%;
  right: 21.34%;
  bottom: 73.13%;
  /* left: 17.68%; */
}
.onChainLoans {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: rgba(237, 240, 244, 0.8);
  width:125px;
}
}

@media only screen and (max-device-width: 362px){
  .desktopVersionInner{
    position: absolute;
    top: 546px;
    left: calc(50% - 175px);
    border-radius: var(--br-xs);
    background: linear-gradient(103.94deg, #041a3b, #10479b);
    width: 350px;
    height: 170px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--color-whitesmoke-200);
  }
  .groupParent5 {
    position: absolute;
    top: 0;
    left: 150px;
    width: 123px;
    height: 52px;
    font-size: var(--font-size-2xl);
    color: var(--color-white);
    font-family: var(--font-rubik);
  }
  .groupParent3{
    position: absolute;
    top: 76px;
    left: 150px;
    width: 101px;
    height: 52px;
    font-size: var(--font-size-2xl);
    color: var(--color-white);
    font-family: var(--font-rubik);
  }
}