.frameChild {
  position: absolute;
  top: 232.5px;
  left: 0;
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-darkslateblue);
  width: 187.5px;
  height: 67.5px;
}
.tokenization {
  position: absolute;
  top: 255.8px;
  left: 40.3px;
  font-weight: 600;
}
.copperCoinSvgrepoCom1Icon {
  position: absolute;
  top: 169.5px;
  left: 69.4px;
  width: 48.8px;
  height: 48.8px;
  overflow: hidden;
  object-fit: contain;
}
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  width: 188px;
  height: 300px;
  overflow: hidden;
  background-image: url(/public/frame-1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-mid-3);
  color: var(--color-white);
  font-family: var(--font-inter);
}

@media only screen and (max-device-width: 440px){

  .rectangleParent {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-xs);
    width: 188px;
    height: 300px;
    overflow: hidden;
    background-image: url(/public/frame-1@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    font-size: var(--font-size-mid-3);
    color: var(--color-white);
    font-family: var(--font-inter);
  }
  
}